import { getAPIUrl } from "../helpers/urls";

/**
 * Fetches category
 * @param {string} options.businessId
 * @param {string} options.env
 * @param {string} options.storeId
 * @param {string} options.categoryId
 * @param {string} options.checkoutUrlId
 * @param {boolean} options.convertToCatalog
 * @returns {Category | Catalog} category
 */
export async function getCategory({
  businessId,
  env,
  storeId,
  categoryId,
  checkoutUrlId,
  convertToCatalog,
}) {
  const response = await fetch(
    `${getAPIUrl(env)}/api/e/businesses/${encodeURIComponent(
      businessId,
    )}/categories/${encodeURIComponent(categoryId)}?checkoutUrlId=${encodeURIComponent(
      checkoutUrlId,
    )}&storeId=${encodeURIComponent(storeId)}${convertToCatalog ? "&convertToCatalog=true" : ""}`,
    {
      headers: {
        Accept: "application/json",
      },
    },
  );

  return response.json();
}
