import { signal, computed } from "@preact/signals";

/**
 * Creates an order item with proper signal bindings
 * @param {number} data.taxes (optional) - order level taxes
 * @param {number} data.categoryId (optional)
 * @param {number} data.name (optional)
 * @param {number} data.productId (optional)
 * @param {number} data.quantity (optional)
 * @param {number} data.sku (optional)
 * @param {number} data.status (optional)
 * @param {number} data.taxExempted (optional)
 * @param {number} data.unitOfMeasure (optional)
 * @param {number} data.unitPrice (optional)
 * @returns
 */
export function createLegacyOrderItemSignal(data) {
  const quantity = signal(data?.quantity || 1);
  const unitPrice = signal(data?.unitPrice || 0);
  const name = signal(data?.name || "Custom item");

  // variants
  const selectedVariants = {};
  if (data?.selectedVariants?.[0]?.selectableVariations?.length) {
    data?.selectedVariants?.[0]?.selectableVariations.forEach((variation) => {
      selectedVariants[variation.attribute] = variation.values || [];
    });
  }
  const variants = signal(selectedVariants);

  const orderItem = computed(() => {
    const orderItem = {
      categoryId: data?.categoryId,
      discount: 0,
      fee: 0,
      name: name.value,
      productId: data?.productId,
      quantity: quantity.value,
      sku: data?.sku,
      status: data?.status || "FULFILLED",
      unitOfMeasure: data?.unitOfMeasure || "EACH",
      unitPrice: unitPrice.value,
    };

    // variants
    if (
      Object.keys(variants.value || {}).some((attribute) => {
        return variants.value[attribute].length;
      })
    ) {
      orderItem.selectedVariants = data?.selectedVariants?.map((variant) => {
        return {
          selectableVariations: variant.selectableVariations
            ?.filter((variation) => {
              return variants.value[variation.attribute]?.length;
            })
            .map((variation) => {
              // add priceDelta to item unitPrice
              variants.value[variation.attribute].forEach((value) => {
                if (value?.priceDelta?.amount) {
                  orderItem.unitPrice += value?.priceDelta?.amount;
                }
              });

              return {
                attribute: variation.attribute,
                values: variants.value[variation.attribute],
              };
            }),
          sku: variant.sku,
        };
      });
    }

    // running item pre-tax total
    let preTaxAmount = orderItem.unitPrice * quantity.value;

    // discounts
    if (data?.discounts?.length) {
      orderItem.discounts = data.discounts;
      orderItem.discount = data.discount;

      preTaxAmount -= data.discounts.reduce((p, c) => {
        return p + (c.appliedBeforeTax ? c.amount : 0);
      }, 0);
    }

    // surcharges
    if (data?.fees?.length) {
      orderItem.fees = data.fees;
      orderItem.fee = data.fee;

      preTaxAmount += data.fees.reduce((p, c) => {
        return p + (c.appliedBeforeTax ? c.amount : 0);
      }, 0);
    }

    // tax
    if (data?.taxExempted) {
      orderItem.taxes = [];
      orderItem.tax = 0;
    } else {
      orderItem.taxes = (data?.taxes || [])
        .map((tax) => {
          if (tax.amount) {
            return {
              id: tax.id,
              name: tax.name,
              type: tax.type,
              amountPrecision: tax.amountPrecision || 2,
              amount: tax.amount,
              catalogLevel: tax.catalogLevel,
              externalId: tax.externalId,
              taxExempted: tax.taxExempted,
            };
          } else if (tax.rate) {
            return {
              id: tax.id,
              name: tax.name,
              type: tax.type,
              amountPrecision: 6,
              // note: here we're just dividing by 100 to convert the percentage to decimal so it does not need to be adjusted per currency
              amount: 100 * tax.rate * preTaxAmount,
              catalogLevel: tax.catalogLevel,
              externalId: tax.externalId,
              taxExempted: tax.taxExempted,
            };
          }
        })
        .filter((tax) => {
          return tax?.amount;
        });

      // orderItem.tax is amountPrecision 2
      orderItem.tax = Math.round(
        orderItem.taxes.reduce((p, c) => {
          return p + c.amount;
        }, 0) / Math.pow(10, 4),
      );
    }

    return orderItem;
  });

  return { name, quantity, variants, unitPrice, orderItem };
}
