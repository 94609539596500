import { signal, computed } from "@preact/signals";
import { createAddressSignal } from "./address";

/**
 * Creates a signal from a unified billing object
 * @param {Shipping} data
 * @returns
 */
export function createBillingSignal(data) {
  const firstName = signal(data?.firstName || "");
  const lastName = signal(data?.lastName || "");
  const companyName = signal(data?.companyName || "");
  const phone = signal(data?.phone || "");
  const email = signal(data?.email || "");

  const address = signal(createAddressSignal(data?.address || {}));

  const billing = computed(() => {
    const billingObject = {};

    if (firstName.value) {
      billingObject.firstName = firstName.value;
    }
    if (lastName.value) {
      billingObject.lastName = lastName.value;
    }
    if (companyName.value) {
      billingObject.companyName = companyName.value;
    }
    if (phone.value) {
      billingObject.phone = phone.value;
    }
    if (email.value) {
      billingObject.email = email.value;
    }
    if (address.value?.address?.value) {
      billingObject.address = address.value.address.value;
    }

    return billingObject;
  });

  return {
    firstName,
    lastName,
    companyName,
    phone,
    email,
    address,
    billing,
  };
}
