import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

import "../../style/ProductRow.css";

const ProductRow = ({ order, product, addProduct }) => {
  const configsContext = useContext(Configs);

  function handleClick() {
    addProduct(product);
  }

  // check how many of the product are already in the order
  const inOrder = order?.order?.value?.items?.reduce((total, item) => {
    return item?.productId === product.id ? total + item.quantity : total;
  }, 0);

  // truncate product description
  let description = product?.description || "";
  if (description.length > 100) {
    description = `${description.slice(0, 100)}…`;
  }

  return (
    <div className="catalog-product" onClick={handleClick}>
      {inOrder ? <div className="catalog-product-count">{inOrder}</div> : null}
      <div className="catalog-product-info">
        <h3 className="catalog-product-name">{product.name}</h3>
        <div className="catalog-product-price">
          {formatIntegerCurrencyString(
            product.price?.amount,
            configsContext?.configs?.currency,
            configsContext?.configs?.ecommerceLocaleLanguage,
          )}
        </div>
        <p className="catalog-product-description">{description}</p>
      </div>
      <div className="catalog-product-picture">
        {product.imageUrl?.length ? <img src={product.imageUrl[0]} alt={product.name} /> : null}
      </div>
    </div>
  );
};

export default ProductRow;
