import { Text } from "preact-i18n";
import { route } from "preact-router";
import { useContext, useState, useEffect } from "preact/hooks";

import { charge } from "../../rest/charge";

import { Configs } from "../../helpers/context";
import { getCardLogo } from "../../helpers/card";
import { getActionButtonStyle } from "../../helpers/button";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import {
  SURCHARGE_LOAD,
  SURCHARGE_CANCEL_BUTTON_CLICK,
  SURCHARGE_CONFIRM_BUTTON_CLICK
} from "../../helpers/event-definitions";

import ConfirmationModal from "../shared/ConfirmationModal";

import "../../style/SurchargeConfirmation.css";

const SurchargeConfirmation = ({
  setErrorMsg,
  setShowError,
  surchargeData,
  setTransaction,
  setSurchargeData,
  setShowSurchargeConfirmation,
  trackCustomEvent
}) => {
  const configsContext = useContext(Configs);

  const [charging, setCharging] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);

  if (!surchargeData) {
    return null;
  }

  const { chargeOptions, surchargeFee, event } = surchargeData;
  const payButtonStyle = getActionButtonStyle(configsContext?.configs?.ecommerceCheckoutButtonColor);
  //amounts should already be numbers, but just in case
  const totalAmount = Number(chargeOptions.amounts.transactionAmount) + Number(surchargeFee.amount);

  // track custom event on load
  useEffect(() => {
    if (trackCustomEvent) {
      trackCustomEvent(SURCHARGE_LOAD);
    }
  }, []);

  const onCancel = () => {
    if (trackCustomEvent) {
      trackCustomEvent(SURCHARGE_CANCEL_BUTTON_CLICK);
    }

    setSurchargeData(null);
    setShowSurchargeConfirmation(false);
  };

  const onConfirm = async () => {
    if (trackCustomEvent) {
      trackCustomEvent(SURCHARGE_CONFIRM_BUTTON_CLICK);
    }

    const chargeOptionsWithFees = {
      ...chargeOptions,
      amounts: {
        ...chargeOptions.amounts,
        transactionAmount: totalAmount,
        transactionFees: [surchargeFee],
      },
    };

    setCharging(true);

    try {
      const transaction = await charge(configsContext?.configs?.env, chargeOptionsWithFees);

      if (transaction) {
        setCharging(false);
        setTransaction(transaction);

        setSurchargeData(null);
        setShowSurchargeConfirmation(false);
  
        route("/transaction-complete");
      }
    } catch (error) {
      console.error("[SurchargeConfirmation]", error, error?.stack);

      setErrorMsg("Transaction failed. Please try again.");
      setShowError(true);

      setCharging(false);

      setTimeout(() => {
        setShowError(false);
      }, 5000);

      //cancel in case of error because nonce is already consumed and we won't be able to charge again
      onCancel();
    }
  };

  const totalAmountString = formatIntegerCurrencyString(
    totalAmount,
    chargeOptions.amounts.currency,
    configsContext?.configs?.ecommerceLocaleLanguage,
  );

  const subTotalAmountString = formatIntegerCurrencyString(
    chargeOptions.amounts.transactionAmount,
    chargeOptions.amounts.currency,
    configsContext?.configs?.ecommerceLocaleLanguage,
  );

  const surchargeAmountString = formatIntegerCurrencyString(
    surchargeFee.amount,
    chargeOptions.amounts.currency,
    configsContext?.configs?.ecommerceLocaleLanguage,
  );

  return (
    <div className="container surcharge-confirmation">
      <div className="surcharge-confirmation-header">
        <img
          className="surcharge-confirmation-header-icon"
          src="https://d85ecz8votkqa.cloudfront.net/payments-hub/info-icon.svg"
          alt="Info"
        />
        <h2 className="surcharge-confirmation-header-title">
          <Text id="SURCHARGE_CONFIRMATION_TITLE">Confirm Credit Surcharge</Text>
        </h2>
      </div>
      {event.data ? (
        <div className="surcharge-confirmation-card">
          <div className="surcharge-confirmation-card-logo">
            <img src={getCardLogo(event.data.cardType)} alt="Card Logo" />
          </div>
          <div className="surcharge-confirmation-card-number">
            ****{event.data.cardLast4}
          </div>
        </div>
      ) : null}
      <div className="surcharge-confirmation-amounts">
        <div className="surcharge-confirmation-amounts-item">
          <div className="surcharge-confirmation-amounts-item-text">
            <Text id="SURCHARGE_CONFIRMATION_SUBTOTAL">Sub Total</Text>
          </div>
          <div className="surcharge-confirmation-amounts-item-amount">
            {subTotalAmountString}
          </div>
        </div>
        <div className="surcharge-confirmation-amounts-item">
          <div className="surcharge-confirmation-amounts-item-text">
            <Text id="SURCHARGE_CONFIRMATION_CREDIT_SURCHARGE">Credit Surcharge</Text>
          </div>
          <div className="surcharge-confirmation-amounts-item-amount">
            {surchargeAmountString}
          </div>
        </div>
        <div className="surcharge-confirmation-amounts-item surcharge-confirmation-amounts-total">
          <div className="surcharge-confirmation-amounts-item-text">
            <Text id="SURCHARGE_CONFIRMATION_TOTAL">Total</Text>
          </div>
          <div className="surcharge-confirmation-amounts-item-amount">
            {totalAmountString}
          </div>
        </div>
      </div>
      <div className="surcharge-confirmation-actions">
        <button
          className="pay-button surcharge-confirmation-actions-button surcharge-confirmation-actions-confirm"
          onClick={onConfirm}
          disabled={charging}
          style={payButtonStyle}
        >
          {charging ? (
            <Text id="PAYMENT_BUTTON_CHARGING">Charging...</Text>
          ) : (
            <Text id="PAYMENT_BUTTON_AMOUNT" fields={{ amount: totalAmountString }}>
              Pay {totalAmountString}
            </Text>
          )}
        </button>
        <button
          className="pay-button surcharge-confirmation-actions-button surcharge-confirmation-actions-cancel"
          onClick={() => setConfirmationModalOpen(true)}
          disabled={charging}
        >
          <Text id="PAYMENT_BUTTON_CANCEL">Cancel</Text>
        </button>
      </div>

      <ConfirmationModal
        isOpen={isConfirmationModalOpen}
        onCancel={() => setConfirmationModalOpen(false)}
        onConfirm={onCancel}
        titleText={<Text id="CANCEL_TRANSACTION_TITLE">Cancel Transaction</Text>}
        confirmText={<Text id="CANCEL_TRANSACTION_CANCEL">Yes, Cancel</Text>}
        cancelText={<Text id="CANCEL_TRANSACTION_CONTINUE">No, Continue</Text>}
      >
        <p>
          <Text id="CANCEL_TRANSACTION_DESCRIPTION">
            Are you sure you want to cancel the transaction.
          </Text>
        </p>
      </ConfirmationModal>
    </div>
  );
};

export default SurchargeConfirmation;
