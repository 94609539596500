import { formatIntegerCurrencyString } from "../helpers/formatters";
import { translate } from "preact-i18n";
import { I18N_MESSAGE_LIST } from "./context";

/**
 * Prepares order level adjustments by marking each adjustment as order level.
 * 
 * @param {Array<Object>} adjustments - The adjustments to be prepared.
 * @returns {Array<Object>} The prepared adjustments with an added `orderLevel` property set to true.
 */
const prepOrderLevelAdjustment = (adjustments) =>
  (adjustments || []).map(adjustment => ({ ...adjustment, orderLevel: true}));

/**
 * Aggregates adjustments by their names, summing up their values and counts.
 * 
 * @param {Object} previous - The accumulator object containing the aggregated adjustments.
 * @param {Object} current - The current adjustment being processed.
 * @returns {Object} The updated accumulator with the current adjustment aggregated.
 */
const mountAggregate = (previous, current) => {
  const { name } = current;
  const { value, currencyCode } = current.amount;
  const previousAmountValue = previous[name] ? previous[name].amount.value : 0;
  const previousCount = previous[name] ? previous[name].count : 0;
  const newCount = previousCount + 1;

  let appliedBeforeTax;

  if (previous[name]) {
    appliedBeforeTax = previous[name].appliedBeforeTax;
  } else {
    // Some adjustments like taxes don't set the `appliedBeforeTax` attribute
    // In those cases, we want to leave `appliedBeforeTax=undefined`
    if (current.appliedBeforeTax !== undefined && current.appliedBeforeTax !== null) {
      appliedBeforeTax = current.appliedBeforeTax;
    }
  }

  return {
    ...previous,
    [name]: {
      name,
      amount: { currencyCode, value: previousAmountValue + value },
      count: newCount,
      orderLevel: current.orderLevel,
      appliedBeforeTax,
    },
  };
};

/**
 * Parses the adjustments into an aggregate form, omitting details unnecessary for the summary.
 * 
 * @param {Array<Object>} adjustments - The adjustments to be parsed.
 * @param {number} numOfLineItems - The number of line items, used for calculations.
 * @param {string} i18nLocale - The i18nLocale to pass into the preact-i18n translate function.
 * @returns {Array<Object>} The aggregated adjustments.
 */
const parseAggregates = (adjustments, numOfLineItems, i18nLocale) => {
  const aggregateWithoutDetails = Object.values(adjustments.reduce(mountAggregate, {}));

  return aggregateWithoutDetails.map((adjustment) => {
    const { name, amount, count, orderLevel, appliedBeforeTax} = adjustment;

    let appendKey;
    let preTax = 2;

    if (appliedBeforeTax !== undefined && appliedBeforeTax !== null) {
      appendKey = appliedBeforeTax ? '_preTax' : '_postTax';
      preTax = appliedBeforeTax ? 1 : 3;
    }

    let i18nKey;

    if (numOfLineItems > 1) {
      if (numOfLineItems === count || orderLevel === true) {
        i18nKey = `appliesAllItems${appendKey || ''}`;
      } else if (count === 1) {
        i18nKey = `appliesSingleItem${appendKey || ''}`
      } else {
        i18nKey = `appliedMultipleItems${appendKey || ''}`
      }
    }

    return {
      name,
      amount,
      value: formatIntegerCurrencyString(amount.value, amount.currencyCode),
      count,
      details: i18nKey && translate(
        i18nKey,
        "",
        I18N_MESSAGE_LIST[i18nLocale],
        {count},
        undefined,
        "",
      ),
      preTax: preTax,
    };
  });
}

const toNegativeValue = (discount) => ({
  ...discount,
  amount: { ...discount.amount, value: -discount.amount.value},
  value: formatIntegerCurrencyString(-discount.amount.value, discount.amount.currencyCode), // need to create a formatCurrency
})

export const getBreakdown = ({ lineItems, shippingLines, nonSignalOrderData, i18nLocale }) => {
  const orderLineItems = lineItems || [];

  const lineItemsTaxes = orderLineItems
    .map((item) => item.taxes || [])
    .reduce((result, taxes) => result.concat(taxes), [])
    .filter((tax) => !tax.included && !tax.exempted);

  const lineItemFees = orderLineItems
    .map((item) => item.fees || [])
    .reduce((result, fees) => result.concat(fees), [])

  const lineItemDiscounts = orderLineItems
    .map((item) => item.discounts || [])
    .reduce((result, discounts) => result.concat(discounts), [])

  const filteredTaxes = (nonSignalOrderData?.taxes || []).filter(tax => tax.additional === true);

  const allFees = parseAggregates(
    [...(prepOrderLevelAdjustment(nonSignalOrderData?.fees) || []), ...lineItemFees],
    orderLineItems.length,
    i18nLocale
  );

  const allDiscounts = parseAggregates(
    [...(prepOrderLevelAdjustment(nonSignalOrderData?.discounts) || []), ...lineItemDiscounts],
    orderLineItems.length,
    i18nLocale
  );

  const allTaxes = parseAggregates(
    [...(prepOrderLevelAdjustment(filteredTaxes) || []), ...lineItemsTaxes],
    orderLineItems.length,
    i18nLocale
  );

  const allShipping = parseAggregates(
    prepOrderLevelAdjustment(shippingLines) || [],
    orderLineItems.length,
    i18nLocale
  );

  const adjustmentTaxTotal = allTaxes.reduce((result, taxes) => result + taxes.amount.value, 0);
  const adjustmentDiscountTotal = allDiscounts.reduce((result, discounts) => result + discounts.amount.value, 0);
  const adjustmentFeeTotal = allFees.reduce((result, fees) => result + fees.amount.value, 0);
  const adjustmentShippingTotal = allShipping.reduce((result, shipping) => result + shipping.amount.value, 0);

  const adjustments = {
    adjustments: [
    ...allTaxes,
    ...allFees,
    ...allDiscounts.map(toNegativeValue),
    ...allShipping
    ].filter((adjustment) => 
      adjustment.amount.value !== 0), 
    totals: {
      taxTotal: adjustmentTaxTotal, 
      discountTotal: adjustmentDiscountTotal, 
      feeTotal: adjustmentFeeTotal,
      shippingTotal: adjustmentShippingTotal
  }};

  return adjustments;
}

/**
 * Returns an order item with product information attached.
 * @param {OrderItem} item
 * @param {Product} product
 */
export function initOrderItemWithProduct(product, item) {
  const orderItem = { ...(item || {}) };

  orderItem.externalId = product.externalId || orderItem.externalId;
  orderItem.name = product.name || orderItem.name;
  orderItem.productId = product.id || orderItem.productId;
  orderItem.sku = product.sku || orderItem.sku;
  orderItem.unitOfMeasure = product.unitOfMeasure || orderItem.unitOfMeasure;
  orderItem.unitPrice = product.price?.amount || orderItem.unitPrice;

  if (!orderItem.quantity) {
    orderItem.quantity = 1;
  }

  // if has variations
  if (product.selectableVariants?.length) {
    orderItem.selectedVariants = product.selectableVariants.map((variant) => {
      return {
        selectableVariations: variant?.selectableVariations?.map((variation) => {
          return {
            attribute: variation.attribute,
            values:
              variation.cardinality === "1" // if it's a single select, default to the first item
                ? [
                    {
                      ...(variation.values.find((value) => !!value.defaultValue) ||
                        variation.values[0]),
                    },
                  ]
                : [],
          };
        }),
        sku: variant.sku,
      };
    });
  }

  return orderItem;
}

/**
 * Returns an order line item with product information attached.
 * @param {LineItem} item
 * @param {Product} product
 */
export function initLineItemWithProduct(product, item) {
  const currencyCode = product.salePrice?.currencyCode || product.price?.currencyCode;

  const lineItem = { ...(item || {}) };

  lineItem.active = product.active === false ? false : true;
  lineItem.type = product.type || lineItem.type;
  lineItem.name = product.name || lineItem.name;
  lineItem.unitAmount = {
    value: product.salePrice?.value || product.price?.value,
    currencyCode,
  };
  lineItem.unitAmountIncludesAdjustments = false;
  lineItem.productId = product.productId || lineItem.productId;

  lineItem.totals = {
    ...(item?.totals || {}),
  };

  lineItem.details = {
    ...(item?.details || {}),
  };
  lineItem.details.sku = product.sku || lineItem.details.sku;
  lineItem.details.productAssetUrl =
    product.assets?.find((asset) => asset.type === "IMAGE")?.url ||
    lineItem.details.productAssetUrl;
  lineItem.details.unitOfMeasure = product.unitPriceMeasurement || lineItem.details.unitOfMeasure;

  if (!lineItem.quantity) {
    lineItem.quantity = 1;
  }

  // if has variants, choose the first by default
  const selectedVariant = product?.variantProducts?.[0];

  // if has variants, update the line item productId with the variantId
  if (selectedVariant) {
    lineItem.productId = selectedVariant.productId;
    lineItem.active = selectedVariant.active === false ? false : true;

    // update some info on line item w/ variant info instead
    if (selectedVariant.sku) {
      lineItem.details.sku = selectedVariant.sku;
    }
    if (selectedVariant.price?.value) {
      lineItem.unitAmount.value = selectedVariant.salePrice?.value || selectedVariant.price.value;
    }
    if (selectedVariant.assets?.find((asset) => asset.type === "IMAGE")) {
      lineItem.details.productAssetUrl = selectedVariant.assets?.find(
        (asset) => asset.type === "IMAGE",
      )?.url;
    }
  }

  // if has options, save to selectedAddons
  if (product.options?.length) {
    const selectedOptions = product.options
      .map((option) => {
        // in general we prefer using `presentation` instead of `name`
        // since the `name` actually isn't exposed in any UI – it's just a train_case
        // version of the `presentation`. but we fall back to `name` just in case
        switch (option.type) {
          case "VARIANT_LIST":
            // select variant list
            const selectedVariantOption = (product?.variantOptionMapping || []).find(
              (mapping) => mapping.name === option.name,
            );

            if (selectedVariantOption) {
              // if the variant is pre-selected, just add it
              const selectedValue = (option.values || []).find(
                (value) => value.name === selectedVariantOption.value,
              );

              return {
                type: option.type,
                attribute: option.presentation || option.name,
                values: [
                  selectedValue?.presentation || selectedValue?.name || selectedVariant?.value,
                ],
              };
            }

            // the value (aka name) for the current option that's in the selected variant
            const selectedVariantValue = selectedVariant?.variantOptionMapping?.find(
              (variantOption) => variantOption.name === option.name,
            )?.value;

            // the full value object from the current option corresponding to the selected variant
            const selectedOption = option.values?.find(
              (value) => value.name === selectedVariantValue,
            );

            return {
              type: option.type,
              attribute: option.presentation || option.name,
              values: [
                selectedOption?.presentation || selectedOption?.name || selectedVariantValue,
              ],
            };
          case "LIST":
            if (option.cardinality === "1") {
              // choose the first by default
              return {
                type: option.type,
                attribute: option.presentation || option.name,
                values: [
                  {
                    name: option.values?.[0]?.presentation || option.values?.[0]?.name,
                    costAdjustment: {
                      value: option.values?.[0]?.costAdjustment?.value || 0,
                      currencyCode,
                    },
                  },
                ],
              };
            } else {
              // choose none
              return {
                type: option.type,
                attribute: option.presentation || option.name,
                values: [],
              };
            }
          case "TEXT":
            // default to blank
            return {
              type: option.type,
              attribute: option.presentation || option.name,
              values: [],
            };
        }
      })
      .filter((v) => v);

    // variant defining options go to selectedOptions; rest go to selectedAddons
    lineItem.details.selectedAddons = selectedOptions.filter(
      (option) => option.type !== "VARIANT_LIST",
    );
    lineItem.details.selectedOptions = selectedOptions.filter(
      (option) => option.type === "VARIANT_LIST",
    );
  }

  return lineItem;
}

/**
 * Tells us if an order is a legacy (Poynt) or a unified order
 * @param {Order} order
 * @returns
 */
export function isUnifiedOrder(order) {
  return order?.lineItems?.length || order?.totals;
}

/**
 * Gets an order total
 * @param {Order} order
 * @returns {number} total
 */
export function getOrderTotal(order) {
  return order?.isLegacy
    ? order?.order?.value?.amounts?.transactionAmount
    : order?.order?.value?.totals?.transactionAmount;
}

/**
 * Gets an order total minus tip and tax
 * @param {Order} order
 * @returns {number} total
 */
export function getOrderTotalMinusTipAndTax({ order, isLegacy }) {
  return isLegacy
    ? order?.order?.value?.amounts?.transactionAmount - order?.order?.value?.amounts?.tipAmount - order?.order?.value?.amounts?.taxTotal
    : order?.order?.value?.totals?.transactionAmount - order?.order?.value?.totals?.tipAmount - order?.order?.value?.totals?.taxTotal?.value;
}

/**
 * Gets an order total
 * @param {Order} order
 * @returns {number} total
 */
export function getOrderSubTotal(order) {
  return order?.isLegacy
    ? order?.order?.value?.amounts?.subTotal
    : order?.order?.value?.totals?.subTotal?.value;
}

/**
 * Gets an order total before taxes and shipping fees
 * @param {Order} order
 * @returns {number} subtotal
 */
export function getOrderTippableTotal(order) {
  return order?.isLegacy
    ? (order?.order?.value?.amounts?.subTotal || 0) +
        (order?.order?.value?.amounts?.feeTotal || 0) +
        (order?.order?.value?.amounts?.discountTotal || 0)
    : (order?.order?.value?.totals?.subTotal?.value || 0) +
        (order?.order?.value?.totals?.feeTotal?.value || 0) -
        (order?.order?.value?.totals?.discountTotal?.value || 0);
}

/**
 * Gets transaction amounts from an order
 * @param {Order} order
 * @returns {TransactionAmounts} amounts
 */
export function getTransactionAmountsForOrder(order, configs) {
  return order?.isLegacy
    ? {
        ...order?.order?.value?.amounts,
      }
    : {
        orderAmount: order?.order?.value?.totals?.orderAmount,
        tipAmount: order?.order?.value?.totals?.tipAmount,
        transactionAmount: order?.order?.value?.totals?.transactionAmount,
        currency: configs?.currency,
      };
}

/**
 * Handles a line item change when a particular variant addon is selected
 * No current use case for this as variants are defined by options
 * @param {LineItem} options.lineItem
 * @param {Product[]} options.products
 */
export function handleAddonVariantSelection({ lineItem, products }) {
  // find all selected options corresponding to variants
  const selectedVariantAddons = lineItem.selectedAddons?.value
    ?.filter((addon) => addon?.type?.value === "VARIANT_LIST")
    ?.map((addon) => {
      return {
        name: addon?.selectedAddon?.value?.attribute,
        value: addon?.values?.value?.[0],
      };
    });

  // find the single variant product matching all selected options
  const selectedVariant = (products || []).find((product) => {
    return (product.variantOptionMapping || []).every((variantOption) => {
      // find the option matching the variantOptionMapping element's name
      const productOption = product.options?.find(
        (productOption) =>
          productOption.type === "VARIANT_LIST" && productOption.name === variantOption.name,
      );

      // find the value matching the variantOptionMapping element's value
      const productOptionValue = productOption?.values?.find(
        (value) => value.name === variantOption.value,
      );

      // match the option and value against the selected one
      return selectedVariantAddons?.find(
        (addon) =>
          addon.name === (productOption.presentation || productOption.name) &&
          addon.value?.name === (productOptionValue.presentation || productOptionValue.name),
      );
    });
  });

  // update some info on line item w/ variant info instead
  if (selectedVariant) {
    if (selectedVariant.productId) {
      lineItem.productId.value = selectedVariant.productId;
    }
    if (selectedVariant.sku) {
      lineItem.sku.value = selectedVariant.sku;
    }
    if (selectedVariant.price?.value) {
      lineItem.unitAmount.value = selectedVariant.salePrice?.value || selectedVariant.price.value;
    }
    if (selectedVariant.assets?.find((asset) => asset.type === "IMAGE")) {
      lineItem.productAssetUrl.value = selectedVariant.assets?.find(
        (asset) => asset.type === "IMAGE",
      )?.url;
    }
    if (typeof selectedVariant.active !== "undefined") {
      lineItem.active.value = selectedVariant.active;
    }
  }
}

/**
 * Handles a line item change when a particular variant option is selected
 * @param {LineItem} options.lineItem
 * @param {Product[]} options.products
 */
export function handleOptionVariantSelection({ lineItem, products }) {
  // find all selected options corresponding to variants
  const selectedVariantOptions = lineItem.selectedOptions?.value
    ?.filter((selectedOption) => selectedOption?.type?.value === "VARIANT_LIST")
    ?.map((selectedOption) => {
      return {
        name: selectedOption?.selectedOption?.value?.attribute,
        value: selectedOption?.values?.value?.[0],
      };
    });

  // find the single variant product matching all selected options
  const selectedVariant = (products || []).find((product) => {
    return (product.variantOptionMapping || []).every((variantOption) => {
      // find the option matching the variantOptionMapping element's name
      const productOption = product.options?.find(
        (productOption) =>
          productOption.type === "VARIANT_LIST" &&
          productOption?.name?.toLowerCase() === variantOption?.name?.toLowerCase(),
      );

      // find the value matching the variantOptionMapping element's value
      const productOptionValue = productOption?.values?.find(
        (value) =>
          variantOption?.value?.toLowerCase() === (value?.presentation?.toLowerCase() || value?.name?.toLowerCase())
      );

      return selectedVariantOptions?.find(
        (selectedVariantOption) =>
          selectedVariantOption?.name?.toLowerCase() ===
            (productOption?.presentation?.toLowerCase() || productOption?.name?.toLowerCase()) &&
          selectedVariantOption?.value?.toLowerCase() ===
            (productOptionValue?.presentation?.toLowerCase() ||
              productOptionValue?.name?.toLowerCase()),
      );
    });
  });

  // update some info on line item w/ variant info instead
  if (selectedVariant) {
    if (selectedVariant.productId) {
      lineItem.productId.value = selectedVariant.productId;
    }
    if (selectedVariant.sku) {
      lineItem.sku.value = selectedVariant.sku;
    }
    if (selectedVariant.price?.value) {
      lineItem.unitAmount.value = selectedVariant.salePrice?.value || selectedVariant.price.value;
    }
    if (selectedVariant.assets?.find((asset) => asset.type === "IMAGE")) {
      lineItem.productAssetUrl.value = selectedVariant.assets?.find(
        (asset) => asset.type === "IMAGE",
      )?.url;
    }
    if (typeof selectedVariant.active !== "undefined") {
      lineItem.active.value = selectedVariant.active;
    }
  }
}
