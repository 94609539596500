export type CurrencyFormatOptions = {
  symbolPrefix?: string;
  thousandsSeparator: string;
  decimalSeparator: string;
  symbolSuffix?: string;
  precision?: number;
  allowNegative?: boolean;
};

export const defaultFormattingOptions: CurrencyFormatOptions = {
  thousandsSeparator: ",",
  decimalSeparator: ".",
  symbolPrefix: "$",
  symbolSuffix: "",
  precision: 2,
};

export const localeCurrencyFormattingOptionsMap: Record<string, Partial<CurrencyFormatOptions>> = {
  "fr-ca": {
    symbolPrefix: "",
    symbolSuffix: " $",
    decimalSeparator: ",",
    precision: 2,
  },
  "en-us": defaultFormattingOptions,
};

export const DEFAULT_LOCALE = "en-us";

/**
 * Gets formatting options per locale; defaults to US
 * @param {string} locale
 * @returns {FormattingOptions} formattingOptions
 */
export function getFormattingOptions(locale) {
  return (
    localeCurrencyFormattingOptionsMap[locale] || localeCurrencyFormattingOptionsMap[DEFAULT_LOCALE]
  );
}
