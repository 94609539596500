/**
 * Gets poynt API URL
 * @param {string} env
 * @returns
 */
export function getAPIUrl(env) {
  switch (env) {
    case "development":
      return "http://local.poynt.net";
    case "dev-private":
      return "https://ci.poynt.net";
    case "ci":
      return "https://ci.poynt.net";
    case "test":
      return "https://test.poynt.net";
    case "ote":
      return "https://ote.poynt.net";
    default:
      return "https://poynt.net";
  }
}

/**
 * Gets poynt collect URL
 * @param {string} env
 * @returns
 */
export function getCollectUrl(env) {
  switch (env) {
    case "development":
      return "https://cdn.poynt.net/ci/collect-ci.js";
      return "http://local.cdn.poynt.net/collect.js";
    case "dev-private":
      return "https://cdn.poynt.net/ci/collect-ci.js";
    case "ci":
      return "https://cdn.poynt.net/ci/collect-ci.js";
    case "st":
      return "https://cdn.poynt.net/test/collect-test.js";
    case "ote":
      return "https://cdn.poynt.net/ote/collect-ote.js";
    case "test":
      return "https://cdn.poynt.net/test/collect-test.js";
    default:
      return "https://cdn.poynt.net/collect.js";
  }
}

/**
 * Gets VT URL
 * @param {string} env
 * @returns
 */
export function getVTUrl(env) {
  switch (env) {
    case "development":
      // return "https://vt-ci.poynt.net";
      return "http://vt.local.poynt.net";
    case "dev-private":
      return "https://vt-ci.poynt.net";
    case "ci":
      return "https://vt-ci.poynt.net";
    case "st":
      return "https://vt-test.poynt.net";
    case "ote":
      return "https://vt-ote.poynt.net";
    case "test":
      return "https://vt-test.poynt.net";
    default:
      return "https://vt.poynt.net";
  }
}
