import { useContext } from "preact/hooks";

import FulfillmentPickupOption from "./FulfillmentPickupOption";

import { Configs } from "../../helpers/context";

import "../../style/FulfillmentForm.css";

const FULFILLMENT_OPTIONS = {
  PICKUP: "PICKUP",
};

const FulfillmentForm = ({ payLink, channels, order }) => {
  // fulfillment options
  let fulfillmentOptions = [];

  const configsContext = useContext(Configs);

  // pickup fulfillment options
  if (payLink?.metadata?.fulfillmentOptions?.PICKUP) {
    const retailChannels = (channels || []).filter((channel) => {
      return (
        channel.type === "RETAIL" &&
        channel.registeredStores?.some((store) => {
          return store.storeId === configsContext?.configs.storeId && store.status === "ENABLED";
        })
      );
    });

    if (retailChannels.length) {
      fulfillmentOptions.push({
        type: FULFILLMENT_OPTIONS.PICKUP,
        channels: retailChannels,
      });
    }
  }

  if (!fulfillmentOptions?.length) {
    return;
  }

  return (
    <div className="fulfillment-container container">
      {fulfillmentOptions.map((option) => {
        if (option.type === FULFILLMENT_OPTIONS.PICKUP) {
          return (
            <FulfillmentPickupOption payLink={payLink} channels={option.channels} order={order} />
          );
        }
      })}
    </div>
  );
};

export default FulfillmentForm;
