import { Fragment } from "preact";
import { useContext, useEffect } from "preact/hooks";
import { route } from "preact-router";
import { Text } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { getActionButtonStyle } from "../../helpers/button";

import ProductRow from "./ProductRow";

import "../../style/CatalogPage.css";
import { EMIT_LOAD_CATALOG } from "../../helpers/event-definitions";

// component for catalog flow
const CatalogPage = ({ order, catalog, payLink, setProduct, c2Analytics }) => {
  const configsContext = useContext(Configs);

  // custom background color on the pay button
  const checkoutButtonStyle = getActionButtonStyle(
    configsContext?.configs?.ecommerceCheckoutButtonColor,
  );

  function continueToPayment() {
    window?.parent?.postMessage(
      { sender: "pay-buttons-js", action: "setCheckoutContinue", value: true },
      "*",
    );

    route("/checkout");
  }

  function addProduct(product) {
    setProduct(product);
    route("/item/add");
  }

  if (!catalog) {
    return;
  }

  // track custom event on first load
  useEffect(() => {
    if (c2Analytics?.trackCustomEvent) {
      c2Analytics.trackCustomEvent(EMIT_LOAD_CATALOG);
    }
  }, []);

  return (
    <div className="container catalog-page-section">
      <h1 className="catalog-title">{catalog.name}</h1>
      <p className="catalog-subtitle">{catalog.displayMetadata?.[0]?.operatingHours}</p>
      <hr />
      {catalog.products
        ?.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
        .map((product) => {
          return (
            <ProductRow order={order} payLink={payLink} product={product} addProduct={addProduct} />
          );
        })}

      {catalog.categories
        ?.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
        ?.filter((category) => category?.products?.length)
        .map((category) => {
          return (
            <Fragment>
              <h2 className="category-title">{category.name}</h2>
              {category.products
                ?.sort((a, b) => (a.displayOrder || 0) - (b.displayOrder || 0))
                .map((product) => {
                  return (
                    <ProductRow
                      order={order}
                      payLink={payLink}
                      product={product}
                      addProduct={addProduct}
                      categoryId={category.id}
                    />
                  );
                })}
            </Fragment>
          );
        })}

      {order?.order?.value?.items?.length ? (
        <div
          className="catalog-continue"
          role="button"
          onClick={continueToPayment}
          style={checkoutButtonStyle}
        >
          <span className="catalog-continue-text">
            <Text id="ITEM_LAYOUT_CONTINUE_TEXT">Continue to Payment</Text>
          </span>
        </div>
      ) : null}
    </div>
  );
};

export default CatalogPage;
