import { Text } from "preact-i18n";
import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { getOrderTotal } from "../../helpers/order";
import { getInvoiceRecurrenceRate } from "../../helpers/invoice";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

import "../../style/PaymentAgreement.css";

const PaymentAgreement = ({
  payLink,
  order,
  paymentMethod,
  saveCardOnFile
}) => {
  const configsContext = useContext(Configs);

  const isAch = paymentMethod === "ach";
  const businessName = configsContext?.configs?.ecommerceBusinessName;
  const localeLanguage = configsContext?.configs?.ecommerceLocaleLanguage;
  const isRecurringInvoice = payLink.urlType === "N" && !!payLink.invoice?.recurringPlan;

  if (isRecurringInvoice && saveCardOnFile) {
    const recurrenceRate = getInvoiceRecurrenceRate(payLink?.invoice);

    const amountString = formatIntegerCurrencyString(
      getOrderTotal(order),
      payLink.currency,
      localeLanguage,
    );

    return (
      <div className="payment-agreement">
        <p className="payment-agreement-text">
          <Text id="PAYMENT_AGREEMENT_RECURRING" fields={{ recurrenceRate, amount: amountString }}>
            By saving my payment method information,I agree to the Saved Payment Method Terms,
            and that payment will either be charged in installments to the payment method above
            or we will charge the payment method above the recurring payment in an amount of
            {amountString} {recurrenceRate} either until your installment agreement is completed
            or until you cancel your recurring payment agreement which you may cancel in accordance with
            our cancellation policy which we can provide upon request.
          </Text>
        </p>

        {isAch ? (
          <p className="payment-agreement-text">
            <Text id="PAYMENT_AGREEMENT_ACH_RECURRING" fields={{ businessName }}>
              By clicking Pay, you authorize {businessName} to debit
              your bank account periodically as agreed above.
              You may amend or cancel this authorization at any time by providing notice to
              {businessName} with 30 (thirty) days notice.
            </Text>
          </p>
        ) : null}
      </div>
    )
  }

  if (isAch) {
    return (
      <div className="payment-agreement">
        <p className="payment-agreement-text">
          <Text id="PAYMENT_AGREEMENT_ACH" fields={{ businessName }}>
            By clicking Pay, you authorize {businessName} to debit
            the bank account specified above for any amount owed for charges
            arising from your use of {businessName}'s services and/or purchase
            of products from {businessName}, pursuant to {businessName}'s terms,
            until this authorization is revoked.
          </Text>
        </p>
      </div>
    );
  }
};

export default PaymentAgreement;
