import { signal, computed } from "@preact/signals";

import { FulfillmentStatus } from "../enums/fulfillmentStatus";
import { FulfillmentMode } from "../enums/fulfillmentMode";

import { createSelectedAddonSignal } from "./selectedAddon";
import { createSelectedOptionSignal } from "./selectedOption";

const DEFAULT_ITEM_NAME = "Custom item";
const DEFAULT_ITEM_SKU = "CSTM";

/**
 * Creates a line item with proper signal bindings
 * @param {LineItem} data
 * @returns
 */
export function createLineItemSignal(data, configs) {
  const productId = signal(data?.productId);
  const name = signal(data?.name || DEFAULT_ITEM_NAME);
  const quantity = signal(data?.quantity || 1);
  const unitAmount = signal(data?.unitAmount?.value || 0);
  const status = signal(data?.status || FulfillmentStatus.UNFULFILLED);
  const fulfillmentMode = signal(data?.fulfillmentMode || FulfillmentMode.NONE);
  const fulfillmentChannelId = signal(data?.fulfillmentChannelId || null);
  const productAssetUrl = signal(data?.details?.productAssetUrl);
  const active = signal(data?.active === false ? false : true);

  // addons
  const sku = signal(data?.details?.sku || DEFAULT_ITEM_SKU);
  const selectedAddons = signal(
    (data?.details?.selectedAddons || []).map((addon) => {
      return createSelectedAddonSignal(addon);
    }),
  );
  const selectedOptions = signal(
    (data?.details?.selectedOptions || []).map((option) => {
      return createSelectedOptionSignal(option);
    }),
  );

  const currencyCode = configs?.currency || data?.currency || "USD";

  const lineItem = computed(() => {
    let unitAmountWithAdjustments = unitAmount.value;

    if (data.unitAmountIncludesAdjustments === false) {
      // calculate the final unit amount with all adjustments applied
      // so we can properly calculate taxes etc
      unitAmountWithAdjustments =
        unitAmountWithAdjustments +
        selectedAddons.value.reduce((p, addon) => {
          return (
            p +
            addon?.values?.value?.reduce((q, value) => {
              return q + (value?.costAdjustment?.value || 0);
            }, 0)
          );
        }, 0); // attach selectedAddons cost adjustments
    }

    const lineItem = {
      id: data?.id,
      externalId: data?.externalId,
      type: data?.type,
      name: name.value,
      status: status.value,
      fulfillmentMode: fulfillmentMode.value,
      fulfillmentChannelId: fulfillmentChannelId.value,
      totals: {
        discountTotal: {
          value: 0,
          currencyCode,
        },
        feeTotal: {
          value: 0,
          currencyCode,
        },
        subTotal: {
          value: 0,
          currencyCode,
        },
        taxTotal: {
          value: 0,
          currencyCode,
        },
      },
      unitAmount: {
        value: unitAmountWithAdjustments,
        currencyCode,
      },
      quantity: quantity.value,
      productId: productId.value,
      details: {
        sku: sku.value,
        productAssetUrl: productAssetUrl.value,
        unitOfMeasure: data?.details?.unitOfMeasure,
        selectedAddons: selectedAddons.value
          .map((addon) => {
            return addon?.selectedAddon?.value;
          })
          .filter((addon) => addon.values?.[0]),
        selectedOptions: selectedOptions.value
          .map((option) => {
            return option?.selectedOption?.value;
          })
          .filter((option) => option.values?.[0]),
      },
      shipping: data?.shipping,
      tags: data?.tags,
      serviceStartAt: data?.serviceStartAt,
      serviceEndsAt: data?.serviceEndsAt,
      notes: data?.notes || [],
    };

    // running item pre-tax total
    let preTaxAmount = unitAmountWithAdjustments * quantity.value;

    // discounts
    if (data?.discounts?.length) {
      lineItem.discounts = data.discounts;
      lineItem.totals.discountTotal.value = data?.totals?.discountTotal?.value;

      // preTaxAmount -= data.discounts.reduce((p, c) => {
      //   return p + (c.appliedBeforeTax ? c.amount?.value : 0);
      // }, 0);
    }

    // surcharges
    if (data?.fees?.length) {
      lineItem.fees = data.fees;
      lineItem.totals.feeTotal.value = data?.totals?.feeTotal?.value;

      // preTaxAmount += data.fees.reduce((p, c) => {
      //   return p + (c.appliedBeforeTax ? c.amount?.value : 0);
      // }, 0);
    }

    // tax
    if (data?.taxes?.length) {
      lineItem.taxes = (data.taxes || [])
        .map((tax) => {
          if (tax.amount?.value) {
            // already calculated
            return tax;
          } else if (tax.ratePercentage) {
            return {
              externalIds: tax.externalIds,
              referenceId: tax.referenceId,
              name: tax.name,
              included: tax.included,
              exempted: tax.exempted,
              ratePercentage: "" + tax.ratePercentage, // convert to string
              amount: {
                value: Math.round((parseFloat(tax.ratePercentage) * preTaxAmount) / 100),
                currencyCode,
              },
            };
          }
        })
        .filter((tax) => tax?.amount?.value);

      lineItem.totals.taxTotal.value =
        lineItem.taxes.reduce((p, tax) => p + tax.amount?.value, 0) ||
        data?.totals?.taxTotal?.value;

      // TODO: handle pre- and post-tax discounts and fees
    }

    return lineItem;
  });

  return {
    productId,
    active,
    name,
    quantity,
    status,
    fulfillmentMode,
    fulfillmentChannelId,
    unitAmount,
    sku,
    productAssetUrl,
    selectedAddons,
    selectedOptions,
    lineItem,
  };
}
