import { Fragment } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { route } from "preact-router";
import { Text } from "preact-i18n";

import LegacyOrderSummaryItem from "../shared/LegacyOrderSummaryItem.jsx";
import { useMediaQuery } from "react-responsive";

import { Configs } from "../../helpers/context";
import { formatDate } from "../../helpers/formatters/date";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { EMIT_LOAD_INVOICE } from "../../helpers/event-definitions";

import SurchargeConfirmation from "../SurchargeConfirmation";
import PoyntCollectForm from "../PoyntCollectForm/PoyntCollectForm";
import CentralOrderSummary from "../shared/CentralOrderSummary";
import TipsForm from "../shared/TipsForm";

import "../../style/InvoicePage.css";

const InvoicePage = ({
  order,
  payLink,
  product,
  setTransaction,
  setShowError,
  setErrorMsg,
  c2Analytics,
}) => {
  const configsContext = useContext(Configs);
  const [showDetails, setShowDetails] = useState(false);
  const isDesktopOrLaptop = useMediaQuery({ minWidth: 820 });
  const [surchargeData, setSurchargeData] = useState(null);
  const [showSurchargeConfirmation, setShowSurchargeConfirmation] = useState(false);

  // if we already paid, we need to go straight to the payment page
  if (payLink?.invoice?.status === "PAID") {
    setTransaction(payLink?.order?.transactions?.[0]);
    route("/transaction-complete");
    return;
  }

  // track custom event on first load
  useEffect(() => {
    if (c2Analytics?.trackCustomEvent) {
      c2Analytics.trackCustomEvent(EMIT_LOAD_INVOICE);
    }
  }, []);

  useEffect(() => {
    setShowDetails(isDesktopOrLaptop);
  }, [isDesktopOrLaptop]);

  const invoiceId = payLink.invoice?.customInvoiceId || payLink.invoice?.orderId?.slice(0, 8);

  const toggleDetails = () => {
    setShowDetails(!showDetails);
  };

  const header = (
    <Fragment>
      {/* For mobile view, show/hide details button */}
      <div className="mobile-view">
        <button 
          onClick={toggleDetails}
          className="invoice-button"
        >
          {showDetails ? "Hide Details" : "Show Details"}
          <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path d="M18.5302 8.61682C18.391 8.48331 18.1969 8.40625 17.9999 8.40625C17.803 8.40625 17.6089 8.48331 17.4697 8.61682L11.9999 13.8586L6.53021 8.61682C6.3894 8.48286 6.19892 8.40783 6.00051 8.40817C5.80209 8.40852 5.6119 8.48421 5.4716 8.61867C5.3313 8.75313 5.25232 8.93539 5.25195 9.12554C5.25159 9.31569 5.32988 9.49823 5.46967 9.63317L11.4697 15.3832C11.6089 15.5167 11.803 15.5937 11.9999 15.5937C12.1969 15.5937 12.391 15.5167 12.5302 15.3832L18.5302 9.63317C18.6695 9.49973 18.7499 9.31376 18.7499 9.125C18.7499 8.93623 18.6695 8.75027 18.5302 8.61682Z" fill="#444444"/>
          </svg>
        </button>
      </div>
      
      {/* For desktop view, or if showDetails is true in mobile view, show full details */}
      {(showDetails || isDesktopOrLaptop) && (
        <div>
          <div className="invoice-details">
            <div>
              <span className="invoice-heading-bill-to">
                <Text id="INVOICE_CUSTOMER_BILL_TO">Customer (Bill To)</Text>
              </span>
              <div className="invoice-details-contact">
                {payLink.invoice?.firstName} {payLink.invoice?.lastName}
              </div>
              <div className="invoice-details-contact">{payLink.invoice?.customerEmail}</div>
            </div>
            <div>
              <div className="invoice-details-heading-section">
                <span className="invoice-details-heading">
                  <Text id="INVOICE_INVOICE">Invoice</Text>
                </span>{" "}
                #{invoiceId}
              </div>
              <div className="invoice-details-sent-info">
                <span className="invoice-details-date">
                  <Text id="INVOICE_DATE">Invoice Date</Text>:{" "}
                </span>
                {formatDate(
                  payLink.invoice?.executedAt || payLink.invoice?.createdAt,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                  { dateStyle: "long" },
                )}
              </div>
              <div className={"invoice-details-due-by" + (new Date() > new Date(payLink.invoice?.dueAt) ? " invoice-details-overdue" : "")}>
                <span className="invoice-details-date">
                  <Text id="INVOICE_DUE_DATE">Due Date</Text>:{" "}
                </span>
                {formatDate(
                  payLink.invoice?.dueAt || payLink.invoice?.executedAt || payLink.invoice?.createdAt,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                  { dateStyle: "long" },
                )}
              </div>
            </div>
          </div>
          {payLink.invoice?.message ? (
            <Fragment>
              <div class="invoice-message">{payLink.invoice.message}</div>
            </Fragment>
          ) : null}
          <div className="order-items">
            <div className="order-items-header">
              <span className="order-item">
              <Text id="ORDER_ITEM">Item</Text>
              </span>
              <span className="order-item">
              <Text id="ORDER_ITEM_QTY">Qty</Text>
              </span>
              <span className="order-item">
              <Text id="ORDER_ITEM_AMOUNT">Amount</Text>
              </span>
            </div>
          </div>
          {order?.isLegacy ? (
            <div>
              {order?.order?.value?.items?.map((item) => {
                return <LegacyOrderSummaryItem item={item} />;
              })}
            </div>
          ) : (
            <CentralOrderSummary order={order}/>
          )}
        </div>
      )}
    </Fragment>
  );

  return (
    <div>
      <div className="invoice-page">
        <div className="collect-form-container">
          <div className={`container ${showSurchargeConfirmation ? 'hidden' : ''}`}>
            {!payLink?.metadata?.allowTips ||
            payLink?.metadata?.allowTips === "0" ||
            !payLink?.enableGratuity ||
            payLink?.enableGratuity === "0" ? (
              <div className="collect-form-total">
                <span style="float:left">
                  <Text id="CHECKOUT_TOTAL">Total</Text>
                </span>
                <span style="float:right">
                  {order?.isLegacy
                    ? formatIntegerCurrencyString(
                        order?.order?.value?.amounts?.netTotal,
                        configsContext?.configs?.currency,
                        configsContext?.configs?.ecommerceLocaleLanguage,
                      )
                    : formatIntegerCurrencyString(
                        order?.order?.value?.totals?.total?.value,
                        configsContext?.configs?.currency,
                        configsContext?.configs?.ecommerceLocaleLanguage,
                      )}
                </span>
              </div>
            ) : (
              <div className="invoice-tips-form">
                <TipsForm payLink={payLink} order={order} />
              </div>
            )}
            <PoyntCollectForm
              payLink={payLink}
              order={order}
              product={product}
              setTransaction={setTransaction}
              setShowError={setShowError}
              setErrorMsg={setErrorMsg}
              setSurchargeData={setSurchargeData}
              setShowSurchargeConfirmation={setShowSurchargeConfirmation}
            />
          </div>
          {showSurchargeConfirmation ? (
            <SurchargeConfirmation
              setErrorMsg={setErrorMsg}
              setShowError={setShowError}
              surchargeData={surchargeData}
              setTransaction={setTransaction}
              setSurchargeData={setSurchargeData}
              setShowSurchargeConfirmation={setShowSurchargeConfirmation}
              trackCustomEvent={c2Analytics?.trackCustomEvent}
            />
          ) : null}
        </div>
        <div className="invoice-summary">
          <div className="container">
            <div className="invoice-heading">
              <h1>
                <Text
                  id="INVOICE_FROM"
                  fields={{ businessName: configsContext?.configs?.ecommerceBusinessName }}
                >
                  Invoice from {configsContext?.configs?.ecommerceBusinessName}
                </Text>
              </h1>
              <div className={"invoice-heading-subtext" + (new Date() > new Date(payLink.invoice?.dueAt) ? " invoice-details-overdue" : "")}>
                <Text id="INVOICE_DUE_ON">Due on</Text>{" "}
                {formatDate(
                  payLink.invoice?.dueAt ||
                    payLink.invoice?.executedAt ||
                    payLink.invoice?.createdAt,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                  { dateStyle: "long" },
                )}
              </div>
            </div>
            <div className="container invoice-order-summary">
              {header}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default InvoicePage;
