import { getAPIUrl } from "../../helpers/urls";

/**
 * Fetches the product being sold in a pay link
 * @param {string} options.businessId
 * @param {string} options.productId
 * @param {Product} product
 */
export async function getProduct({ businessId, env, productId }) {
  const response = await fetch(
    `${getAPIUrl(env)}/api/e/businesses/${encodeURIComponent(
      businessId,
    )}/products/${encodeURIComponent(productId)}`,
    {
      headers: {
        Accept: "application/json",
      },
    },
  );

  return response.json();
}
