import { extractIsoFromLocale, formatIntegerCurrencyDecimal } from "./formatters";
import { getOrderTotal, getTransactionAmountsForOrder } from "./order";

const MIN_PAYLINK_AMOUNT = 100;
const SHIPMENT_DELIVERY_MODE = 'DELIVERY';
const FULFILLMENT_INSTRUCTION = 'SHIP_TO';

function composeItemsWithFulfillmentInstruction(order) {
  if (!order) {
    return;
  }

  const parsedOrder = JSON.parse(order);
  const composedItems = parsedOrder.items.map(item => {
    item.fulfillmentInstruction = FULFILLMENT_INSTRUCTION;

    return item;
  });

  return composedItems;
}

export function validateOrder(_configs, payLink, order) {
  if (payLink.metadata?.isNotesRequired && !order?.notes?.value) {
    return "Notes are required";
  }

  const total = getOrderTotal(order);

  if (total < MIN_PAYLINK_AMOUNT) {
    return "Transaction amount must be greater than $1.00";
  }
}

/**
 * Gets request options for wallet collect
 * @param {Configs} configs
 * @param {PayLink} payLink
 * @param {OrderSignal} order
 * @returns
 */
export function getWalletCollectOptions(configs, payLink, order) {
  const isoCode = configs?.ecommerceLocaleLanguage
    ? extractIsoFromLocale(configs?.ecommerceLocaleLanguage)
    : "US";

  const isAddressRequired = payLink.metadata?.isAddressRequired;
  const collectShippingAddress = payLink.metadata?.collectShippingAddress;
  const requireShipping = isAddressRequired || collectShippingAddress;

  return {
    merchantName: configs?.ecommerceBusinessName,
    currency: payLink.currency,
    country: isoCode,
    requireEmail: true,
    total: {
      label: "TOTAL",
      amount: formatIntegerCurrencyDecimal(getOrderTotal(order), configs?.currency),
    },
    disableWallets: {
      paze: true,
    },
    requireShippingAddress: requireShipping,
    requireShippingMethods: false
  };
}
/**
 * Gets request options for POST /ecommerce/transaction
 * @param {Configs} configs
 * @param {PayLink} payLink
 * @param {OrderSignal} order
 * @returns
 */
export function getEcommerceTransactionOptions(configs, payLink, order, product, nonceEvent) {
  const attachOrderShipments = payLink.metadata?.isAddressRequired || payLink.metadata?.collectShippingAddress;

  let orderObject = {};
  if (order && !order.isLegacy) {
    // create a copy of the order object to avoid modifying the original
    const unifiedOrder = { ...(order?.order?.value || {}) };

    // YOLO-541: these are attributes needed to show
    // the adjustment breakdown in the order summary. They
    // should not be passed into the backend call to capture
    // the transaction
    delete unifiedOrder.adjustments;
    delete unifiedOrder.showAdjustmentBreakdown;

    const unifiedOrderBillingAddress = unifiedOrder?.billing?.address;

    orderObject = {
      unifiedOrder: {
        ...(unifiedOrder),
        billing: {
          firstName: nonceEvent?.firstName,
          lastName: nonceEvent?.lastName,
          email: nonceEvent?.emailAddress,
          phone: nonceEvent?.phone,
          address: {
            addressLine1: nonceEvent?.line1 || unifiedOrderBillingAddress?.addressLine1,
            addressLine2: nonceEvent?.line2 || unifiedOrderBillingAddress?.addressLine2,
            addressLine3: nonceEvent?.line3 || unifiedOrderBillingAddress?.addressLine3,
            adminArea1: nonceEvent?.territory || unifiedOrderBillingAddress?.adminArea1,
            adminArea2: nonceEvent?.city || unifiedOrderBillingAddress?.adminArea2,
            postalCode: nonceEvent?.zip || nonceEvent?.postalCode || unifiedOrderBillingAddress?.postalCode,
            countryCode: nonceEvent?.countryCode || unifiedOrderBillingAddress?.countryCode,
          },
        },
      },
    };
  } else if (order && order.isLegacy) {
    orderObject = {
      order: {
        ...(order?.order?.value || {}),
        ...(payLink.txnId
          ? {
              attributes: {
                ...(order?.order?.value?.attributes || {}),
                txnId: payLink.txnId,
              },
            }
          : {}),
        customer: {
          emails: {
            PERSONAL: {
              emailAddress:
                nonceEvent?.emailAddress ||
                order?.order?.value?.customer?.emails?.PERSONAL?.emailAddress,
              type: "PERSONAL",
            },
          },
          firstName: nonceEvent?.firstName || order?.order?.value?.customer?.firstName,
          lastName: nonceEvent?.lastName || order?.order?.value?.customer?.lastName,
          ...(nonceEvent?.phone
            ? {
                phones: {
                  MOBILE: {
                    areaCode: "",
                    ituCountryCode: "",
                    localPhoneNumber: nonceEvent.phone,
                  },
                },
              }
            : order?.value?.customer?.phones
            ? {
                phones: order?.value?.customer?.phones,
              }
            : {}),
        },
        ...(attachOrderShipments ? {
              orderShipments: [{
                address: {
                  line1: nonceEvent?.shippingLine1 || nonceEvent?.shippingAddress?.addressLines[0],
                  line2: nonceEvent?.shippingLine2 || nonceEvent?.shippingAddress?.addressLines[1],
                  city: nonceEvent?.shippingCity || nonceEvent?.shippingAddress?.locality,
                  territory: nonceEvent?.shippingTerritory || nonceEvent?.shippingAddress?.administrativeArea,
                  postalCode: nonceEvent?.shippingZip || nonceEvent?.shippingAddress?.postalCode,
                },
                deliveryMode: SHIPMENT_DELIVERY_MODE
              }],
              items: composeItemsWithFulfillmentInstruction(JSON.stringify(order?.order))
            } : {}
        )
      }
    };
  }

  return {
    action: "SALE",
    amounts: {
      ...getTransactionAmountsForOrder(order, configs),
      currency: configs?.currency,
    },
    businessId: configs?.businessId,
    checkoutUrlData: {
      ...payLink,
      ...configs,
      product, // needed for the backend to not wipe the order, ugh
      ...orderObject,
      billingAddress: {
        addressLine1: nonceEvent?.line1,
        addressLine2: nonceEvent?.line2,
        addressLine3: nonceEvent?.line3,
        adminArea1: nonceEvent?.territory,
        adminArea2: nonceEvent?.city,
        postalCode: nonceEvent?.zip || nonceEvent?.postalCode || nonceEvent?.zipCode,
        countryCode: nonceEvent?.countryCode,
      }
    },
    context: {
      businessId: configs?.businessId,
      storeId: configs?.storeId,
    },
    notes: order?.notes?.value,
  };
}

const FONTS = '"GD Sherpa", "objektiv-mk2", "Proxima Nova", "Myriad Pro", -apple-system, Helvetica';

// options when instantiating wallet collect
export const WALLET_COLLECT_OPTIONS = {
  paymentMethods: [],
  buttonsContainerOptions: {
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "row",
    style: {
      display: "flex",
      width: "100%",
      gap: ".4rem",
      "margin-bottom": "1rem",
    },
  },
  buttonOptions: {
    width: "100%",
    margin: "5px auto",
    color: "black",
  },
};

// options when instantiating credit card collect
export const COLLECT_OPTIONS = {
  iFrame: {
    width: "100%",
    height: "485px",
    border: "0px",
  },
  style: {
    theme: "ecommerce",
  },
  displayComponents: {
    emailAddress: false,
    firstName: false,
    lastName: false,
    ecommerceFirstName: true,
    ecommerceLastName: true,
    ecommerceEmailAddress: true,
    ecommerceNotes: false,
    zipCode: true,
    labels: true,
    ecommerceLabels: true,
    showEndingPage: false,
    paymentLabel: true,
  },
  additionalFieldsToValidate: ["firstName", "lastName", "emailAddress"],
  enableReCaptcha: true,
  reCaptchaOptions: {
    type: "TEXT",
  },
  customCss: {
    container: {
      color: "#111",
      "font-family": FONTS,
      height: "auto",
      "flex-flow": "row wrap",
      "justify-content": "normal",
      "align-content": "center",
      "margin-top": "-15px",
    },
    inputLabel: {
      color: "#111",
      display: "block",
      "font-size": "15px",
      "font-weight": "700",
      "line-height": "20px",
      "margin-bottom": "7.5px",
      "margin-top": "5px",
      "text-transform": "capitalize",
      "letter-spacing": "0px",
    },
    inputDefault: {
      color: "#111",
      "font-family": FONTS,
      "font-size": "15px",
      "line-height": "20px",
    },
    sectionLabel: {
      "font-size": "13px",
      "line-height": "18px",
      "font-weight": "500",
      "letter-spacing": "0.5px",
      color: "#767676",
      "text-transform": "uppercase",
      "margin-top": "15px",
      "margin-bottom": "10px",
      "padding-left": "0px",
      "padding-right": "0px",
    },
    requiredMark: {
      color: "#ae1302",
      "font-size": "15px",
      "line-height": "20px",
      "margin-left": "3px",
    },
    rowFirstName: {
      width: "50%",
      "padding-left": "0px",
    },
    rowLastName: {
      width: "50%",
      "padding-right": "0px",
    },
    rowCardNumber: {
      width: "75%",
      "padding-left": "0px",
    },
    rowCVV: {
      width: "35%",
      "padding-left": "0px",
    },
    rowExpiration: {
      width: "25%",
      "padding-right": "0px",
    },
    rowZip: {
      width: "65%",
      "padding-right": "0px",
    },
    rowEmailAddress: {
      width: "100%",
      "padding-left": "0px",
      "padding-right": "0px",
    },
    rowShippingZip: {
      width: "100%",
      "padding-left": "0px",
      "padding-right": "0px",
    },
    rowCountry: {
      width: "100%",
      "padding-left": "0px",
      "padding-right": "0px",
    },
    rowAddress: {
      width: "100%",
      "padding-left": "0px",
      "padding-right": "0px",
    },
    rowCity: {
      width: "50%",
      "padding-left": "0px",
    },
    rowTerritory: {
      width: "50%",
      "padding-right": "0px",
    },
    rowSameAsBillingCheckbox: {
      width: "100%",
      "padding-left": "0px",
      "padding-right": "0px",
    },
    rowPhone: {
      width: "100%",
      "margin-bottom": "3px",
      "padding-left": "0px",
      "padding-right": "0px",
    },
  },
};

// card agreement styles
export const CARD_AGREEMENT_STYLES = {
  title: {
    "font-size": "17px",
    "line-height": "24px;",
    "margin-top": "-25px",
  },
  mainText: {
    "font-size": "15px",
    "line-height": "20px",
    margin: "15px 0px 20px",
  },
  agreementContainer: {
    background: "white",
    "box-shadow": "0px 4px 0px rgba(148, 159, 172, 0.2)",
    padding: "25px",
    color: "#111",
    "border-radius": "0",
    "font-size": "15px",
    "line-height": "20px",
  },
  acceptButton: {
    color: "white",
    "background-color": "#2544b7",
    "font-weight": "700",
    "font-size": "15px",
    "line-height": "20px",
    border: "none",
    padding: "12px 13px",
    "border-radius": "2px",
    cursor: "pointer",
  },
  declineButton: {
    color: "#111",
    "background-color": "#e8eaeb",
    "font-weight": "700",
    "font-size": "15px",
    "line-height": "20px",
    border: "none",
    padding: "12px 13px",
    "border-radius": "2px",
    cursor: "pointer",
  },
};

/**
 * Gets collect fields from an order (legacy or new)
 * @param {Order} order
 * @returns
 */
export function getCollectFieldsFromOrder(order) {
  return order?.isLegacy
    ? {
        emailAddress: order?.order?.value?.customer?.emails?.PERSONAL?.emailAddress,
        firstName: order?.order?.value?.customer?.firstName,
        lastName: order?.order?.value?.customer?.lastName,
        phone:
          [
            order?.order?.value?.customer?.phones?.MOBILE?.areaCode,
            order?.order?.value?.customer?.phones?.MOBILE?.localPhoneNumber,
          ]
            .filter((v) => v)
            .join("") || null,
      }
    : {
        emailAddress: order?.order?.value?.billing?.email || order?.order?.value?.shipping?.email,
        firstName:
          order?.order?.value?.billing?.firstName || order?.order?.value?.shipping?.firstName,
        lastName: order?.order?.value?.billing?.lastName || order?.order?.value?.shipping?.lastName,
        phone: order?.order?.value?.billing?.phone || order?.order?.value?.shipping?.phone,
      };
}
