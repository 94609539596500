import { Text } from "preact-i18n";
import classNames from "classnames";

import "../../style/InvoiceAlreadyPaidSection.css";

const InvoiceAlreadyPaidSection = ({ transactionId, isDesktopOrLaptop, amount, businessName }) => {
  const endingPagePaidInvoiceClassNames = classNames({
    "ending-page-mobile-paid-invoice": !isDesktopOrLaptop,
    "ending-page-section-paid-invoice": true,
  });

  return (
    <div className={endingPagePaidInvoiceClassNames}>
      <div className="ok-circle">
        <svg xmlns="http://www.w3.org/2000/svg" width="46" height="46" fill="none">
          <path
            fill="#000"
            fill-rule="evenodd"
            d="M33.258 16.448a.75.75 0 0 1 .044 1.06l-11.98 13.038a1.75 1.75 0 0 1-2.628-.057l-5.268-6.256a.75.75 0 0 1 1.148-.966l5.268 6.256a.25.25 0 0 0 .375.008l11.98-13.038a.75.75 0 0 1 1.06-.045Z"
            clip-rule="evenodd"
          />
          <path
            fill="#111"
            fill-rule="evenodd"
            d="M23 1.75C11.264 1.75 1.75 11.264 1.75 23S11.264 44.25 23 44.25 44.25 34.736 44.25 23 34.736 1.75 23 1.75ZM.25 23C.25 10.435 10.435.25 23 .25S45.75 10.435 45.75 23 35.565 45.75 23 45.75.25 35.565.25 23Z"
            clip-rule="evenodd"
          />
        </svg>
      </div>
      <div className="ending-page-paid-invoice-details-section">
        <div className="ending-page-paid-invoice-description">
          <Text id="ENDING_PAGE_PAID_INVOICE_DESCRIPTION" fields={{ amount, businessName }}>
            You paid {amount} to {businessName}
          </Text>
        </div>
        <div className="ending-page-paid-invoice-transaction-id">
          <Text id="ENDING_PAGE_PAID_INVOICE_TRANSACTION_ID" fields={{ transactionId }}>
            Transaction ID {transactionId}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default InvoiceAlreadyPaidSection;
