import { getAPIUrl } from "../helpers/urls";

/**
 * Fetches the product being sold in a pay link
 * @param {string} options.businessId
 * @param {string} options.storeId
 * @param {string} options.env
 * @param {string} options.productId
 * @param {string} options.checkoutUrlId
 * @param {Product} product
 */
export async function getProduct({ businessId, env, storeId, productId, checkoutUrlId }) {
  const response = await fetch(
    `${getAPIUrl(env)}/api/e/businesses/${encodeURIComponent(
      businessId,
    )}/products/${encodeURIComponent(productId)}?unified=true&checkoutUrlId=${encodeURIComponent(
      checkoutUrlId,
    )}&storeId=${encodeURIComponent(storeId)}`,
    {
      headers: {
        Accept: "application/json",
      },
    },
  );

  return response.json();
}
