import { Text } from "preact-i18n";
import classNames from "classnames";

import usePostMessage from "./../../hooks/post-message";

import "../../style/PaymentSuccessfulSection.css";

const PaymentSuccessfulSection = ({
  transaction,
  transactionId,
  isDesktopOrLaptop,
  amount,
  businessName,
}) => {
  const endingPageClassNames = classNames({
    "ending-page-mobile": !isDesktopOrLaptop,
    "ending-page-section": true,
  });

  return (
    <div className={endingPageClassNames}>
      <div className="check-circle" style="margin-bottom: 10px;">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          color="#2544B7"
          width="80"
          height="80"
          viewBox="0 0 24 24"
          fill="none"
          stroke="currentColor"
          stroke-width="1"
          stroke-linecap="round"
          stroke-linejoin="round"
        >
          <path d="M22 11.08V12a10 10 0 1 1-5.93-9.14"></path>
          <polyline points="22 4 12 14.01 9 11.01"></polyline>
        </svg>
      </div>
      <div className="ending-page-header">
        <Text id="ENDING_PAGE_HEADER">Payment successful!</Text>
      </div>
      <div className="ending-page-description">
        <Text id="ENDING_PAGE_DESCRIPTION" fields={{ amount }}>
          You paid {amount} to
        </Text>
      </div>
      <div className="ending-page-business" fields={{ businessName }}>
        {businessName}
      </div>
      <div className="ending-page-transaction-id">
        <Text id="ENDING_PAGE_TRANSACTION_ID" fields={{ transactionId }}>
          Transaction ID: {transactionId}
        </Text>
      </div>
      {usePostMessage("pay-buttons-js", "setCheckoutComplete", { transaction })}
    </div>
  );
};

export default PaymentSuccessfulSection;
