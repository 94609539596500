const EMIT_LOAD = {
  tcclEvent: "gdp.hub.opl_c2_load",
};

const EMIT_LOAD_FIXED_AMOUNT = {
  tcclEvent: "gdp.hub.opl_c2_load_fixed_amount",
};

const EMIT_LOAD_CUSTOM_AMOUNT = {
  tcclEvent: "gdp.hub.opl_c2_load_custom_amount",
};

const EMIT_LOAD_LEGACY_OPTIONS = {
  tcclEvent: "gdp.hub.opl_c2_load_legacy_options",
};

const EMIT_LOAD_OPTIONS = {
  tcclEvent: "gdp.hub.opl_c2_load_options",
};

const EMIT_LOAD_PRODUCT = {
  tcclEvent: "gdp.hub.opl_c2_load_product",
};

const EMIT_LOAD_CATALOG = {
  tcclEvent: "gdp.hub.opl_c2_load_catalog",
};

const EMIT_LOAD_CATALOG_ADD_PRODUCT = {
  tcclEvent: "gdp.hub.opl_c2_load_catalog_add_product",
};

const EMIT_LOAD_INVOICE = {
  tcclEvent: "gdp.hub.opl_c2_load_invoice",
};

const EMIT_LOAD_PRODUCT_CHECKOUT = {
  tcclEvent: "gdp.hub.opl_c2_load_product_checkout",
};

const EMIT_LOAD_CHECKOUT = {
  tcclEvent: "gdp.hub.opl_c2_load_checkout",
};

const EMIT_LOAD_CATALOG_CHECKOUT = {
  tcclEvent: "gdp.hub.opl_c2_load_catalog_checkout",
};

const EMIT_LOAD_COMPLETE = {
  tcclEvent: "gdp.hub.opl_c2_load_complete",
};

const EMIT_PAY_BUTTON_CLICK = {
  tcclEvent: "gdp.hub.opl_c2_save.button.click",
};

const EMIT_COLLECT_ERROR = {
  tcclEvent: "gdp.hub.opl_c2_collect_error",
};

const EMIT_SUCCESS = {
  tcclEvent: "gdp.hub.opl_c2_success",
};

const EMIT_OPTION = {
  tcclEvent: "gdp.hub.opl_c2_option.click",
};

const EMIT_NOTE = {
  tcclEvent: "gdp.hub.opl_c2_notebox.click",
};

const EMIT_CURRENCY = {
  tcclEvent: "gdp.hub.opl_c2_amount.click",
};

const SURCHARGE_LOAD = {
  tcclEvent: "gdp.hub.opl_c2_surcharge_load",
};

const SURCHARGE_CANCEL_BUTTON_CLICK = {
  tcclEvent: "gdp.hub.opl_c2_surcharge_cancel_button.click",
};

const SURCHARGE_CONFIRM_BUTTON_CLICK = {
  tcclEvent: "gdp.hub.opl_c2_surcharge_confirm_button.click",
};

export {
  EMIT_LOAD,
  EMIT_LOAD_FIXED_AMOUNT,
  EMIT_LOAD_CUSTOM_AMOUNT,
  EMIT_LOAD_LEGACY_OPTIONS,
  EMIT_LOAD_OPTIONS,
  EMIT_LOAD_PRODUCT,
  EMIT_LOAD_CATALOG,
  EMIT_LOAD_CATALOG_ADD_PRODUCT,
  EMIT_LOAD_CATALOG_CHECKOUT,
  EMIT_LOAD_INVOICE,
  EMIT_LOAD_PRODUCT_CHECKOUT,
  EMIT_LOAD_CHECKOUT,
  EMIT_PAY_BUTTON_CLICK,
  EMIT_COLLECT_ERROR,
  EMIT_SUCCESS,
  EMIT_OPTION,
  EMIT_NOTE,
  EMIT_CURRENCY,
  EMIT_LOAD_COMPLETE,
  SURCHARGE_LOAD,
  SURCHARGE_CANCEL_BUTTON_CLICK,
  SURCHARGE_CONFIRM_BUTTON_CLICK,
};
