import { signal, computed } from "@preact/signals";

/**
 * Creates a signal from a unified address object
 * @param {Address} data
 * @returns
 */
export function createAddressSignal(data) {
  const addressLine1 = signal(data?.addressLine1 || "");
  const addressLine2 = signal(data?.addressLine2 || "");
  const addressLine3 = signal(data?.addressLine3 || "");
  const adminArea4 = signal(data?.adminArea4 || "");
  const adminArea3 = signal(data?.adminArea3 || "");
  const adminArea2 = signal(data?.adminArea2 || "");
  const adminArea1 = signal(data?.adminArea1 || "");
  const postalCode = signal(data?.postalCode || "");
  const countryCode = signal(data?.countryCode || "");

  const address = computed(() => {
    const addressObject = {};

    if (addressLine1.value) {
      addressObject.addressLine1 = addressLine1.value;
    }
    if (addressLine2.value) {
      addressObject.addressLine2 = addressLine2.value;
    }
    if (addressLine3.value) {
      addressObject.addressLine3 = addressLine3.value;
    }
    if (adminArea4.value) {
      addressObject.adminArea4 = adminArea4.value;
    }
    if (adminArea3.value) {
      addressObject.adminArea3 = adminArea3.value;
    }
    if (adminArea2.value) {
      addressObject.adminArea2 = adminArea2.value;
    }
    if (adminArea1.value) {
      addressObject.adminArea1 = adminArea1.value;
    }
    if (postalCode.value) {
      addressObject.postalCode = postalCode.value;
    }
    if (countryCode.value) {
      addressObject.countryCode = countryCode.value;
    }

    return addressObject;
  });

  return {
    addressLine1,
    addressLine2,
    addressLine3,
    adminArea4,
    adminArea3,
    adminArea2,
    adminArea1,
    postalCode,
    countryCode,
    address,
  };
}
