import { Fragment } from "preact";
import { useEffect, useContext, useState } from "preact/hooks";
import { route } from "preact-router";
import { Text } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { getActionButtonStyle } from "../../helpers/button";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { EMIT_LOAD_PRODUCT } from "../../helpers/event-definitions";

import SingleSelectOption from "./SingleSelectOption";
import SingleSelectAddon from "./SingleSelectAddon";
import MultiSelectAddon from "./MultiSelectAddon";
import TextOption from "./TextOption";

import "../../style/ProductPage.css";

const ProductPage = ({ payLink, order, id, product, c2Analytics }) => {
  const [lineItem, setLineItem] = useState(null);

  const configsContext = useContext(Configs);

  // initialize the order item that we'll be modifying here
  useEffect(() => {
    const item = order.lineItems.value[id];

    if (c2Analytics?.trackCustomEvent) {
      c2Analytics.trackCustomEvent(EMIT_LOAD_PRODUCT);
    }

    setLineItem(item);
  }, [id, order, product]);

  function continueToPayment() {
    window?.parent?.postMessage(
      { sender: "pay-buttons-js", action: "setCheckoutContinue", value: true },
      "*",
    );

    route("/checkout");
  }

  // modifiers section
  const options = (product?.options || []).map((option) => {
    if (option.type === "VARIANT_LIST") {
      // variants – must select one regardless of cardinality
      const selectedVariant = (product?.variantOptionMapping || []).find(
        (mapping) => mapping.name === option.name,
      );
      if (selectedVariant) {
        // if the variant is pre-selected, just render the text
        const selectedValue = (option.values || []).find(
          (value) => value.name === selectedVariant.value,
        );

        return (
          <div className="item-modifiers-variant">
            <span class="modifier-title">{option.presentation || option.name}: </span>
            <strong>
              {selectedValue?.presentation || selectedValue?.name || selectedVariant.value}
            </strong>
          </div>
        );
      } else {
        // otherwise treat as single select list
        return (
          <SingleSelectOption
            option={option}
            variants={product?.variants}
            products={product?.variantProducts}
            lineItem={lineItem}
          />
        );
      }
    } else if (option.type === "LIST") {
      if (option.cardinality === "1") {
        // single select list
        return <SingleSelectAddon option={option} lineItem={lineItem} />;
      } else {
        // multi select list
        return <MultiSelectAddon option={option} lineItem={lineItem} />;
      }
    } else if (option.type === "TEXT") {
      // text field
      return <TextOption option={option} lineItem={lineItem} />;
    }
  });

  // CTA button
  const actionButtonStyle =
    lineItem?.active?.value === false
      ? null
      : getActionButtonStyle(configsContext?.configs?.ecommerceCheckoutButtonColor);

  const picture =
    payLink.picture ||
    lineItem?.details?.productAssetUrl ||
    product?.assets?.find((asset) => asset.type === "IMAGE")?.url;

  // class to attach to entire form if no picture is present
  const pc = picture ? "" : "no-picture";

  const actionButton = (
    <button
      onClick={continueToPayment}
      className={`item-layout-continue ${pc}`}
      style={actionButtonStyle}
      disabled={lineItem?.active?.value === false}
    >
      <span className="item-layout-continue-text">
        {lineItem?.active?.value === false ? (
          <Text id="ITEM_LAYOUT_NOT_AVAILABLE">Not Available</Text>
        ) : (
          <Text id="ITEM_LAYOUT_CONTINUE_TEXT">Continue to Payment</Text>
        )}
      </span>
    </button>
  );

  const productForm = lineItem ? (
    <Fragment>
      <h1 className={`item-layout-title ${pc}`}>{payLink.title}</h1>
      <h1 className={`item-layout-amount ${pc}`}>
        {formatIntegerCurrencyString(
          lineItem.unitAmount?.value,
          payLink.currency,
          configsContext?.configs?.ecommerceLocaleLanguage,
        )}
      </h1>
      <p className={`item-layout-description ${pc}`}>{payLink.description}</p>
      <div className={`item-modifiers-section ${pc}`}>{options}</div>
      <div className={`item-layout-cart-size ${pc}`}>
        <div
          className="item-layout-decrement-counter row ._25"
          role="button"
          onClick={() => {
            lineItem.quantity.value = Math.max(1, lineItem.quantity.value - 1);
          }}
        >
          <span className="item-layout-decrement-text">-</span>
        </div>
        <div className="item-layout-counter row ._50">
          <span className="item-layout-counter-text">{lineItem.quantity.value}</span>
        </div>
        <div
          className="item-layout-increment-counter row ._25"
          role="button"
          onClick={() => {
            lineItem.quantity.value = lineItem.quantity.value + 1;
          }}
        >
          <span className="item-layout-increment-text">+</span>
        </div>
      </div>
      {actionButton}
    </Fragment>
  ) : null;

  if (picture) {
    return (
      <div className="container item-layout-section-with-picture">
        <div className="item-picture">
          <img className="item-pic" src={picture} />
        </div>
        <div className="item-description-container">{productForm}</div>
      </div>
    );
  } else {
    // no picture
    return <div className="container item-layout-section">{productForm}</div>;
  }
};

export default ProductPage;
