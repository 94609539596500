import useScript from "./use-script";

const getTcclUrl = (env?: string) => {
  switch (env) {
    case "development":
    case "dev-private":
    case "dev":
    case "ci":
      return "https://img1.dev-wsimg.com/signals/js/clients/scc-c2/scc-c2.js";
    case "st":
    case "ote":
    case "test":
      return "https://img1.test-wsimg.com/signals/js/clients/scc-c2/scc-c2.min.js";
    case "production":
    case "prod":
      return "https://img1.wsimg.com/signals/js/clients/scc-c2/scc-c2.min.js";
    default:
      return "";
  }
};

function useC2Analytics(env?: string, businessId?: string, payLink?: any) {
  const TCCL_URL = getTcclUrl(env);
  const status = useScript(TCCL_URL);
  const scriptReady = status === "ready";

  window._signalsDataLayer = window._signalsDataLayer || [];

  const tcclInstance = window._signalsDataLayer;
  const networkReady = scriptReady && !!tcclInstance;

  const trackCustomEvent = ({ tcclEvent }: TrafficEventOptions, customProperties: Record<string, any>) => {
    try {
      if (env !== "production") {
        if (!networkReady) {
          console.log("Traffic event queued before first load", { tcclEvent });
        } else {
          console.log("Traffic event queued", { tcclEvent });
        }
      }

      tcclInstance.push({
        schema: "add_event",
        version: "v1",
        data: {
          type: "click",
          eid: tcclEvent,
          ...(customProperties ? { custom_properties: customProperties } : {}),
        },
      });
    } catch (err) {
      if (env !== "production") {
        console.log("Traffic event error", { tcclEvent, err });
      }
    }
  };

  return {
    trackCustomEvent,
  };
}

export default useC2Analytics;
