import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

import "../../style/SingleSelectModifier.css";

const SingleSelectModifier = ({ orderItem, variation }) => {
  const configsContext = useContext(Configs);

  function handleChange(e) {
    // change the order item variant variation to the selected one
    orderItem.variants.value = {
      ...orderItem.variants.value,
      [variation.attribute]: [
        {
          ...variation.values[e.target.options.selectedIndex],
        },
      ],
    };
  }

  return (
    <div className="single-select-container">
      <div className="modifier-title">{variation.attribute}</div>
      <div className="modifier-dropdown">
        <select
          value={orderItem.variants.value[variation.attribute]?.[0]?.name}
          onChange={handleChange}
        >
          {variation.values.map((value) => {
            return (
              <option value={value.name}>
                <span className="modifier-value-text">
                  {value.name}
                  {value.priceDelta?.amount
                    ? ` (+${formatIntegerCurrencyString(
                        value.priceDelta.amount,
                        configsContext?.configs?.currency,
                        configsContext?.configs?.ecommerceLocaleLanguage,
                      )})`
                    : ""}
                </span>
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SingleSelectModifier;
