export enum PaymentStatus {
  NONE = "NONE",
  PENDING = "PENDING",
  PROCESSING = "PROCESSING",
  PARTIALLY_PAID = "PARTIALLY_PAID",
  PAID = "PAID",
  PARTIALLY_REFUNDED = "PARTIALLY_REFUNDED",
  REFUNDED = "REFUNDED",
  CANCELED = "CANCELED",
  EXTERNALLY_PROCESSED = "EXTERNALLY_PROCESSED",
}
