import { handleOptionVariantSelection } from "../../helpers/order";

import "../../style/SingleSelectModifier.css";

const SingleSelectOption = ({ lineItem, option, variants, products }) => {

  function handleChange(e) {
    // change the line item selected option to what was selected
    const selectedOption = lineItem.selectedOptions?.value?.find(
      (selectedOption) =>
        selectedOption?.selectedOption?.value?.attribute === (option.presentation || option.name),
    );

    if (selectedOption) {
      const newOption = option.values?.[e.target.options.selectedIndex];
      selectedOption.values.value = [newOption?.presentation || newOption?.name];
    }

    // handle if the option change resulted in a variant change
    handleOptionVariantSelection({ lineItem, products });
  }

  return (
    <div className="single-select-container">
      <div className="modifier-title">{option.presentation || option.name}</div>
      <div className="modifier-dropdown">
        <select
          value={
            lineItem.selectedOptions?.value?.find(
              (selectedOption) =>
                selectedOption?.selectedOption?.value?.attribute ===
                (option.presentation || option.name),
            )?.values?.value?.[0]
          }
          onChange={handleChange}
        >
          {(option.values || []).map((value, i) => {
            return (
              <option value={value.presentation || value.name}>
                <span className="modifier-value-text">
                  {value.presentation}
                </span>
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SingleSelectOption;
