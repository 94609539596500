/**
 * Single page pay link checkout
 * Used for Fixed, Custom amount, Single-select list pay links
 */

import { useContext, useState } from "preact/hooks";

import { Configs } from "../../helpers/context";

import SinglePageForm from "./SinglePageForm";
import SurchargeConfirmation from "../SurchargeConfirmation";
import PoyntCollectForm from "../PoyntCollectForm/PoyntCollectForm";

import classNames from "classnames";

import "../../style/SinglePage.css";

const SinglePage = ({
  payLink,
  order,
  c2Analytics,
  setShowError,
  setErrorMsg,
  setTransaction,
  isDesktopOrLaptop,
}) => {
  const configsContext = useContext(Configs);
  const [surchargeData, setSurchargeData] = useState(null);
  const [showSurchargeConfirmation, setShowSurchargeConfirmation] = useState(false);

  const poyntCollectClassNames = classNames({
    container: true,
    "poynt-collect-form-wallet": configsContext?.configs?.supportApplePay
      || configsContext?.configs?.supportGooglePay,
    "poynt-collect-form": !configsContext?.configs?.supportApplePay
      && !configsContext?.configs?.supportGooglePay,
  });

  return (
    <div className="single-page-layout">
      {payLink.picture ? (
        <div className="single-page-picture">
          <img src={payLink.picture} />
        </div>
      ) : null}
      <div className={`single-page-item ${showSurchargeConfirmation ? 'hidden' : ''}`}>
        <SinglePageForm
          payLink={payLink}
          order={order}
          trackCustomEvent={c2Analytics?.trackCustomEvent}
        />
        <div className={poyntCollectClassNames}>
          <PoyntCollectForm
            payLink={payLink}
            order={order}
            setTransaction={setTransaction}
            setShowError={setShowError}
            setErrorMsg={setErrorMsg}
            setSurchargeData={setSurchargeData}
            setShowSurchargeConfirmation={setShowSurchargeConfirmation}
            trackCustomEvent={c2Analytics?.trackCustomEvent}
          />
        </div>
      </div>
      {showSurchargeConfirmation ? (
        <div className='single-page-item surcharge-item'>
          <SurchargeConfirmation
            setErrorMsg={setErrorMsg}
            setShowError={setShowError}
            surchargeData={surchargeData}
            setTransaction={setTransaction}
            setSurchargeData={setSurchargeData}
            setShowSurchargeConfirmation={setShowSurchargeConfirmation}
            trackCustomEvent={c2Analytics?.trackCustomEvent}
          />
        </div>
      ) : null}
    </div>
  );
};

export default SinglePage;
