import { useContext, useEffect } from "preact/hooks";
import { Text } from "preact-i18n";
import CurrencyInput from "react-currency-input";

import { Configs } from "../../helpers/context";
import { getOrderItemSignal, handleCustomPriceChange } from "../../helpers/customPrice";
import { getFormattingOptions } from "../../helpers/formatters/formatting-options";
import { EMIT_LOAD_CUSTOM_AMOUNT } from "../../helpers/event-definitions";

import TipsForm from "../shared/TipsForm";
import NotesForm from "../shared/NotesForm";

const CustomPriceForm = ({ payLink, trackCustomEvent, order }) => {
  const configsContext = useContext(Configs);

  // track custom event on first load
  useEffect(() => {
    if (trackCustomEvent) {
      trackCustomEvent(EMIT_LOAD_CUSTOM_AMOUNT);
    }
  }, []);

  const formattingOptions = getFormattingOptions(configsContext?.configs?.ecommerceLocaleLanguage);

  return (
    <div className="container item-description-custom-amount">
      <h1 className="item-layout-title">{payLink.title}</h1>
      <p className="item-layout-description">{payLink.description}</p>
      <form>
        <label>
          <Text id="CUSTOM_AMOUNT_LABEL">Enter Amount</Text>
          <span className="required-mark">*</span>
        </label>
        <CurrencyInput
          className="custom-amount-input"
          thousandSeparator={formattingOptions.thousandsSeparator}
          decimalSeparator={formattingOptions.decimalSeparator}
          prefix={formattingOptions.symbolPrefix}
          suffix={formattingOptions.symbolSuffix}
          allowNegativeValue={false}
          value={getOrderItemSignal(order) / 100}
          onChangeEvent={(_event, value) => {
            handleCustomPriceChange(order, configsContext?.configs?.ecommerceLocaleLanguage, value);
            order.tip.value = 0;
            return false;
          }}
        />
      </form>
      <NotesForm
        notesRequired={payLink?.metadata?.isNotesRequired}
        notesSignal={order?.notes}
        trackCustomEvent={trackCustomEvent}
      />
      <TipsForm payLink={payLink} order={order} />
    </div>
  );
};

export default CustomPriceForm;
