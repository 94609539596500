import { formatCurrencyInteger, sanitizeAmountByMarket } from "./formatters";

/**
 * Handler that gets triggered on custom price change
 * @param {Order} order
 * @param {string} language
 * @param {string} maskedValue
 */
export function handleCustomPriceChange(order, language, maskedValue) {
  const value = Math.max(
    0,
    formatCurrencyInteger(sanitizeAmountByMarket(maskedValue || "", language)),
  );

  // update the order item signal
  const item = order.items.value[0];

  if (item.unitPrice.value !== value) {
    item.unitPrice.value = value;
  }

  return value;
}

export function handleCustomTipChange(language, maskedValue) {
  return Math.max(0, formatCurrencyInteger(sanitizeAmountByMarket(maskedValue || "", language)));
}

/**
 * Get Order first item signal
 * @param {Order} order
 */
export function getOrderItemSignal(order) {
  const item = order.items.value[0];

  return item.unitPrice.value || 0;
}
