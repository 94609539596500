import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

import "../../style/TextOption.css";

const TextOption = ({ lineItem, option }) => {
  const configsContext = useContext(Configs);

  function handleChange(e) {
    // change the text selected option to what was selected
    const selectedAddon = lineItem.selectedAddons?.value?.find(
      (addon) => addon?.selectedAddon?.value?.attribute === (option.presentation || option.name),
    );

    if (!selectedAddon) {
      // couldn't find the option to update. should never happen
    }

    if (e?.target?.value?.trim()) {
      selectedAddon.values.value = [
        {
          name: e?.target?.value?.trim(),
          costAdjustment: {
            value: option?.costAdjustment?.value || 0,
            currencyCode: configsContext?.configs?.currency,
          },
        },
      ];
    } else {
      selectedAddon.values.value = [];
    }
  }

  return (
    <div className="text-option-container">
      <div className="modifier-title">
        {option.presentation || option.name}
        {option.costAdjustment?.value
          ? ` (+${formatIntegerCurrencyString(
              option.costAdjustment.value,
              configsContext?.configs?.currency,
              configsContext?.configs?.ecommerceLocaleLanguage,
            )})`
          : ""}
      </div>
      <div className="text-option-input-container">
        <textarea
          type="text"
          rows="1"
          onChange={handleChange}
          maxLength={option.maxLength}
          className="text-option-input"
        ></textarea>
      </div>
    </div>
  );
};

export default TextOption;
