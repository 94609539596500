import { useContext, useEffect, useState } from "preact/hooks";
import { Router, route } from "preact-router";
import { createMemoryHistory } from "history";
import { IntlProvider, Text } from "preact-i18n";

import { useMediaQuery } from "react-responsive";

import { Configs, DEFAULT_CONFIGS } from "../../helpers/context";
import {
  initLineItemWithProduct,
  initOrderItemWithProduct,
  isUnifiedOrder,
} from "../../helpers/order";
import { EMIT_LOAD } from "../../helpers/event-definitions";

import SinglePage from "../SinglePage";
import CheckoutPage from "../CheckoutPage";
import LegacyProductPage from "../LegacyProductPage";
import ProductPage from "../ProductPage";
import EndingPage from "../EndingPage";
import CatalogPage from "../CatalogPage";
import InvoicePage from "../InvoicePage";

import { loadPayLink } from "../../rest/payLink";
import { getCatalog } from "../../rest/legacy/catalog";
import { getCategory } from "../../rest/category";
import { getChannels } from "../../rest/channel";
import { getProduct } from "../../rest/product";
import { getProduct as getLegacyProduct } from "../../rest/legacy/product";
import { getTaxes } from "../../rest/legacy/tax";

import { createLegacyOrderSignal } from "../../signals/legacy/order";
import { createOrderSignal } from "../../signals/order";

import useC2Analytics from "../../hooks/use-c2-analytics";

import "../../style/Application.css";

const Application = () => {
  const [payLink, setPayLink] = useState(null);
  const [order, setOrder] = useState(null);
  const [product, setProduct] = useState(null);
  const [catalog, setCatalog] = useState(null);
  const [transaction, setTransaction] = useState(null);
  const [channels, setChannels] = useState([]);
  const [showError, setShowError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const configsContext = useContext(Configs);

  // load paylink & associated info when app starts
  useEffect(async () => {
    const payLink = await loadPayLink();

    if (payLink.httpStatus === 404) {
      setErrorMessage({
        id: "PAY_LINK_NOT_FOUND",
        text: "We could not find a Pay Link at this address. Please try again.",
      });
      return;
    }

    const promises = [];

    if (payLink.urlType === "I" && payLink.productId) {
      promises.push(
        new Promise(async (resolve) => {
          if (isUnifiedOrder(payLink.order)) {
            // get product from central service
            const product = await getProduct({
              businessId: payLink.businessId,
              env: payLink.env,
              productId: payLink.productId,
              storeId: payLink.storeId,
              checkoutUrlId: payLink.checkoutUrlId,
            });

            setProduct(product);

            payLink.order.lineItems[0] = initLineItemWithProduct(
              product,
              payLink.order.lineItems[0],
            );
          } else {
            // get product from poynt
            const product = await getLegacyProduct({
              businessId: payLink.businessId,
              env: payLink.env,
              productId: payLink.productId,
            });

            setProduct(product);

            payLink.order.items[0] = initOrderItemWithProduct(product, payLink.order.items[0]);
          }

          resolve();
        }),
      );
    } else if (payLink.urlType === "C" && payLink.productId) {
      promises.push(
        new Promise(async (resolve) => {
          setCatalog(
            await getCatalog({
              businessId: payLink.businessId,
              env: payLink.env,
              storeId: payLink.storeId,
              catalogId: payLink.productId,
              checkoutUrlId: payLink.checkoutUrlId,
            }),
          );

          resolve();
        }),
      );
    } else if (payLink.urlType === "T" && payLink.productId) {
      promises.push(
        new Promise(async (resolve) => {
          setCatalog(
            await getCategory({
              businessId: payLink.businessId,
              env: payLink.env,
              storeId: payLink.storeId,
              categoryId: payLink.productId,
              checkoutUrlId: payLink.checkoutUrlId,
              convertToCatalog: true,
            }),
          );

          resolve();
        }),
      );
    }

    // if pickup, get channels
    if (payLink?.metadata?.fulfillmentOptions?.PICKUP) {
      promises.push(
        new Promise(async (resolve) => {
          setChannels(
            await getChannels({
              businessId: payLink.businessId,
              env: payLink.env,
              storeId: payLink.storeId,
              checkoutUrlId: payLink.checkoutUrlId,
            }),
          );

          resolve();
        }),
      );
    }

    // load taxes if necessary (taxes exist on the order, but it's not a unified order)
    const taxes = payLink.order?.items?.[0]?.taxes || payLink.order?.taxes || [];
    if (taxes.length && !isUnifiedOrder(payLink.order)) {
      promises.push(
        new Promise(async (resolve) => {
          payLink.order.taxes = (
            await getTaxes({
              businessId: payLink.businessId,
              env: payLink.env,
              taxes,
            })
          ).filter((v) => v);

          resolve();
        }),
      );
    }

    if (promises.length) {
      await Promise.all(promises);
    }

    setPayLink(payLink);

    // instantiate order signal
    if (isUnifiedOrder(payLink.order)) {
      // unified order
      setOrder(
        createOrderSignal(payLink.order, {
          currency: payLink.currency,
          i18nLocale: payLink.ecommerceLocaleLanguage || DEFAULT_CONFIGS.ecommerceLocaleLanguage,
          isProductPayLink: payLink.urlType === "I" && payLink.productId
        }),
      );
    } else {
      setOrder(createLegacyOrderSignal(payLink.order));
    }
  }, []);

  // update configs once paylink loads
  useEffect(() => {
    if (!payLink) {
      return;
    }

    let urlParams;
    if (window.location.href.indexOf("?") > -1) {
      urlParams = window.location.href.split("?")[1].split("&");
    }

    // set global configs so we can access them anywhere (using `useContext(Configs)`)
    configsContext.setConfigs({
      businessId: payLink.businessId,
      storeId: payLink.storeId,
      currency: payLink.currency,
      domain: payLink.domain,
      env: payLink.env,
      businessWebsite: payLink.businessWebsite || DEFAULT_CONFIGS.businessWebsite,
      businessPhone: payLink.businessPhone || DEFAULT_CONFIGS.businessPhone,
      ecommerceBackgroundColor:
        payLink.ecommerceBackgroundColor || DEFAULT_CONFIGS.ecommerceBackgroundColor,
      ecommerceCheckoutButtonColor:
        payLink.ecommerceCheckoutButtonColor || DEFAULT_CONFIGS.ecommerceCheckoutButtonColor,
      ecommerceBusinessName: payLink.ecommerceBusinessName || DEFAULT_CONFIGS.ecommerceBusinessName,
      ecommerceCheckoutLogoUrl:
        payLink.ecommerceCheckoutLogoUrl || DEFAULT_CONFIGS.ecommerceCheckoutLogoUrl,
      ecommerceLocaleLanguage:
        payLink.ecommerceLocaleLanguage || DEFAULT_CONFIGS.ecommerceLocaleLanguage,
      ecommerceLogoAlignment:
        payLink.ecommerceLogoAlignment || DEFAULT_CONFIGS.ecommerceLogoAlignment,
      ecommerceLogoHeight:
        payLink.ecommerceLogoHeight || DEFAULT_CONFIGS.ecommerceLogoHeight,
      ecommerceShowDisplayName:
        payLink.ecommerceShowDisplayName || DEFAULT_CONFIGS.ecommerceShowDisplayName,
      urlParams,
    });

    // route to proper page based on pay link type
    if (payLink.urlType === "I" && payLink.productId) {
      // product page – 2 step checkout
      if (isUnifiedOrder(payLink.order)) {
        // new product page
        route("/product/0");
      } else {
        // legacy product page
        route("/item/0");
      }
    } else if (payLink.urlType === "C" && payLink.productId) {
      // catalog page – build your cart then checkout
      route("/catalog");
    } else if (payLink.urlType === "T" && payLink.productId) {
      // we're converting categories into Poynt catalogs
      // catalog page – build your cart then checkout
      route("/catalog");
    } else if (payLink.urlType === "N") {
      // invoice
      route("/invoice");
    } else if (isUnifiedOrder(payLink.order)) {
      // non legacy order w/ line items
      route("/checkout");
    } else {
      // single page checkout
      route("/single");
    }
  }, [payLink]);

  const isDesktopOrLaptop = useMediaQuery({ minDeviceWidth: 1224 });
  const isPayLinkBreakpoint = useMediaQuery({ minDeviceWidth: 800 });

  const getHeaderWidthAdjustment = (() => {
    if (payLink?.picture && payLink?.urlType !== "N" && configsContext?.configs?.ecommerceLogoAlignment !== "center") {
      return "850px";
    } else if (payLink?.urlType !== "N" && configsContext?.configs?.ecommerceLogoAlignment !== "center") {
      if (isPayLinkBreakpoint) {
        return "468px";
      } else {
        return "770px";
      }
    } else if (payLink?.urlType === "N" && configsContext?.configs?.ecommerceLogoAlignment !== "center") {
      return "1224px";
    }

    return undefined;
  });

  const c2Analytics = useC2Analytics(
    configsContext?.configs?.env,
    configsContext?.configs?.businessId,
    payLink,
  );

  useEffect(() => {
    if (payLink) {
      // track an initial load event
      c2Analytics?.trackCustomEvent(EMIT_LOAD);
    }
  }, [payLink]);

  // temporary error that pops up on top as a toast message
  let errorToast;
  if (showError) {
    errorToast = (
      <div className="error-toast">
        <div className="error-icon">!</div>
        <span className="error-text">{errorMsg}</span>
        <span
          className="error-x"
          onClick={() => {
            setShowError(false);
            setErrorMsg("");
          }}
        >
          X
        </span>
      </div>
    );
  }

  if (payLink?.status === "R") {
    setErrorMessage({
      id: "PAY_LINK_DISABLED_TEXT",
      text: "This Pay Link has been disabled. Please check with the business.",
    });
  }

  if (payLink?.status === "D") {
    setErrorMessage({
      id: "PAY_LINK_DEACTIVATED_TEXT",
      text: "This Pay Link has been deactivated. Please check with the business.",
    });
  }

  function headerClicked() {
    switch (payLink?.urlType) {
      case "C": // CATALOG
      case "A": // ASSOCIATION
      case "T": // CATEGORY
        route("/catalog");
        break;
      case "I": // ITEM
        if (isUnifiedOrder(payLink?.order)) {
          route("/product/0");
        } else {
          route("/item/0");
        }
        break;
      case "L": // LIST
      case "P": // PAYMENT
      default:
        // do nothing
        break;
    }
  }

  const getContrastColorByHex = (hex) => {
    const fullHex =
        hex.length === 4
          ? `#${hex[1].repeat(2)}${hex[2].repeat(2)}${hex[3].repeat(2)}`
          : hex;

    const r = parseInt(fullHex.substring(1, 3), 16);
    const g = parseInt(fullHex.substring(3, 5), 16);
    const b = parseInt(fullHex.substring(5, 7), 16);
    const brightness = (r * 299 + g * 587 + b * 114) / 1_000;

    return brightness > 128 ? '#000000' : '#aab7c2';
  };

  const footerTextColor = getContrastColorByHex(configsContext?.configs?.ecommerceBackgroundColor);

  return (
    <IntlProvider definition={configsContext?.configs?.messages}>
      <div id="app" style={{ backgroundColor: configsContext?.configs?.ecommerceBackgroundColor }}>
        <div className="header">
          <div
            className={[
              "header-container",
              `align-${configsContext?.configs?.ecommerceLogoAlignment}`,
            ].join(' ').trim()}
            style={{ width: getHeaderWidthAdjustment() }}
            onClick={headerClicked}
          >
            {configsContext?.configs?.ecommerceCheckoutLogoUrl ? (
              <img className="header-logo"
                style={{height: `${configsContext?.configs?.ecommerceLogoHeight}px`}}
                src={configsContext?.configs?.ecommerceCheckoutLogoUrl}
                alt={configsContext?.configs?.ecommerceBusinessName}
              />
            ) : null}
            {configsContext?.configs?.ecommerceShowDisplayName === "1" && (
              <div className="header-business-name">
                {configsContext?.configs?.ecommerceBusinessName}
              </div>
            )}
          </div>
        </div>
        {errorToast}
        {errorMessage ? (
          <div className="app-disabled">
            <Text id={errorMessage.id}>{errorMessage.text}</Text>
          </div>
        ) : payLink ? (
          <Router history={createMemoryHistory()} basename="/checkout">
            <SinglePage
              path="/single"
              payLink={payLink}
              order={order}
              setTransaction={setTransaction}
              setShowError={setShowError}
              setErrorMsg={setErrorMsg}
              c2Analytics={c2Analytics}
            />
            <LegacyProductPage
              path="/item/:id"
              payLink={payLink}
              order={order}
              product={product}
              c2Analytics={c2Analytics}
            />
            <ProductPage
              path="/product/:id"
              payLink={payLink}
              order={order}
              product={product}
              c2Analytics={c2Analytics}
            />
            <CatalogPage
              path="/catalog"
              item={payLink}
              order={order}
              catalog={catalog}
              setProduct={setProduct}
              c2Analytics={c2Analytics}
            />
            <CheckoutPage
              path="/checkout"
              payLink={payLink}
              order={order}
              product={product}
              channels={channels}
              setShowError={setShowError}
              setErrorMsg={setErrorMsg}
              setTransaction={setTransaction}
              c2Analytics={c2Analytics}
            />
            <InvoicePage
              path="/invoice"
              payLink={payLink}
              order={order}
              setShowError={setShowError}
              setErrorMsg={setErrorMsg}
              setTransaction={setTransaction}
              c2Analytics={c2Analytics}
            />
            <EndingPage
              path="/transaction-complete"
              payLink={payLink}
              transaction={transaction}
              isDesktopOrLaptop={isDesktopOrLaptop}
              c2Analytics={c2Analytics}
            />
          </Router>
        ) : null}
        <div className="footer">
          <div className="footer-container">
            <div className="gd-powered-text" style={{ color: footerTextColor }}>
              <Text id="FOOTER_BRANDING_TEXT">Websites and Payments powered by</Text>
            </div>
            <img
              className="gd-powered-img"
              style={{ filter: footerTextColor === "#000000" ? "grayscale(1) invert(1) brightness(0.5)" : "" }}
              src="https://d85ecz8votkqa.cloudfront.net/payments-hub/godaddy-paleblue.png"
              alt="GoDaddy"
            />
          </div>
        </div>
      </div>
    </IntlProvider>
  );
};

export default Application;
