import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

import "../../style/SingleSelectModifier.css";

const SingleSelectAddon = ({ lineItem, option }) => {
  const configsContext = useContext(Configs);

  function handleChange(e) {
    // change the line item selected option to what was selected
    const selectedAddon = lineItem.selectedAddons?.value?.find(
      (addon) => addon?.selectedAddon?.value?.attribute === (option.presentation || option.name),
    );

    if (selectedAddon) {
      const newOption = option.values?.[e.target.options.selectedIndex];
      selectedAddon.values.value = [
        {
          name: newOption?.presentation || newOption?.name,
          costAdjustment: {
            value: newOption?.costAdjustment?.value || 0,
            currencyCode: configsContext?.configs?.currency,
          },
        },
      ];
    }
  }

  return (
    <div className="single-select-container">
      <div className="modifier-title">{option.presentation || option.name}</div>
      <div className="modifier-dropdown">
        <select
          value={
            lineItem.selectedAddons?.value?.find(
              (addon) =>
                addon?.selectedAddon?.value?.attribute === (option.presentation || option.name),
            )?.values?.value?.[0]?.name
          }
          onChange={handleChange}
        >
          {(option.values || []).map((value, i) => {
            return (
              <option value={value.presentation || value.name}>
                <span className="modifier-value-text">
                  {value.presentation}
                  {value.costAdjustment?.value
                    ? ` (+${formatIntegerCurrencyString(
                        value.costAdjustment.value,
                        configsContext?.configs?.currency,
                        configsContext?.configs?.ecommerceLocaleLanguage,
                      )})`
                    : ""}
                </span>
              </option>
            );
          })}
        </select>
      </div>
    </div>
  );
};

export default SingleSelectAddon;
