import { Fragment } from "preact";
import { useContext } from "preact/hooks";
import { Text, translate } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { EMIT_NOTE } from "../../helpers/event-definitions";

const NotesForm = ({ notesRequired, notesSignal, trackCustomEvent }) => {
  const configsContext = useContext(Configs);

  return (
    <Fragment>
      <label className="item-payment-note">
        <span>
          <Text id="NOTES_TITLE">Notes</Text>
        </span>
        {notesRequired ? <small className="item-payment-note-asterisk">*</small> : null}
      </label>
      <div className="item-payment-note-input-container">
        <form>
          <textarea
            type="text"
            placeholder={translate(
              "NOTES_PLACEHOLDER_TEXT",
              "",
              configsContext?.configs?.messages,
              {},
              undefined,
              "Add any details or notes about your purchase here.",
            )}
            onFocus={() => {
              trackCustomEvent(EMIT_NOTE);
            }}
            rows="2"
            onInput={(event) => {
              const inputText = event.target.value;
              if (inputText.length > 512) {
                event.target.value = inputText.slice(0, 512);
              }
              notesSignal.value = event.target.value;
            }}
            onChange={(event) => {
              if (notesSignal.value !== event?.target?.value) {
                notesSignal.value = event?.target?.value || "";
              }
            }}
            className="item-payment-note-input"
          ></textarea>
        </form>
      </div>
    </Fragment>
  );
};

export default NotesForm;
