import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

import "../../style/MultiSelectModifier.css";

const MultiSelectModifier = ({ orderItem, variation }) => {
  const configsContext = useContext(Configs);

  function isChecked(value) {
    return orderItem.variants.value[variation.attribute]?.some((selected) => {
      return selected.name === value.name;
    });
  }

  function handleValueChecked(value) {
    if (isChecked(value)) {
      // uncheck it by removing the value from the variants signal on the order item
      orderItem.variants.value = {
        ...orderItem.variants.value,
        [variation.attribute]: orderItem.variants.value[variation.attribute].filter((selected) => {
          return selected.name !== value.name;
        }),
      };
    } else {
      // check it by adding the value to the variants signal on the order item
      orderItem.variants.value = {
        ...orderItem.variants.value,
        [variation.attribute]: orderItem.variants.value[variation.attribute].concat([value]),
      };
    }
  }

  const checkboxes = variation.values.map((value) => {
    return (
      <label className="modifier-value">
        <input
          key={value.name}
          onClick={handleValueChecked.bind(this, value)}
          type="checkbox"
          checked={isChecked(value)}
          value={value.name}
        />
        <span className="modifier-value-text">
          {value.name}
          {value.priceDelta?.amount
            ? ` (+${formatIntegerCurrencyString(
                value.priceDelta.amount,
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )})`
            : ""}
        </span>
      </label>
    );
  });

  return (
    <div className="multi-select-container">
      <div className="modifier-title">{variation.attribute}</div>
      <div className="modifier-checkboxes">{checkboxes}</div>
    </div>
  );
};

export default MultiSelectModifier;
