import { useEffect, useState } from "preact/hooks";
import { Text } from "preact-i18n";

import { formatAddress } from "../../helpers/formatters/address";

const FulfillmentPickupOption = ({ channels, order }) => {
  if (!channels?.length) {
    return;
  }

  const [selectedChannel, setSelectedChannel] = useState(channels[0]);

  useEffect(() => {
    if (selectedChannel) {
      order.pickupChannelId.value = selectedChannel.channelId;
    }
  }, [selectedChannel]);

  function handleClick(channel) {
    setSelectedChannel(channel);
  }

  return (
    <div className="fulfillment-option">
      <div className="fulfillment-option-title">
        <Text id="FULFILLMENT_PICKUP_LOCATION">Pickup Location</Text>
      </div>
      <div className="fulfillment-option-select">
        {channels?.length > 1 ? (
          channels.map((channel) => (
            <label className="fulfillment-option-label">
              <input
                type="radio"
                name="fulfillment-pickup"
                checked={selectedChannel?.channelId === channel.channelId}
                onClick={() => handleClick(channel)}
              />
              <span className="fulfillment-option-name">
                <strong>{channel.name}</strong>
                <span>
                  {channel.location?.address ? formatAddress(channel.location.address) : ""}
                </span>
              </span>
            </label>
          ))
        ) : (
          <span className="fulfillment-option-name">
            <strong>{channels[0].name}</strong>
            <span>
              {channels[0].location?.address ? formatAddress(channels[0].location.address) : ""}
            </span>
          </span>
        )}
      </div>
    </div>
  );
};

export default FulfillmentPickupOption;
