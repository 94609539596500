import { getVTUrl } from "../helpers/urls";

/**
 * Charges a transaction
 * @param {string} env
 * @param {ChargeOptions} data
 * @param {Product} product
 */
export async function charge(env, data) {
  const response = await fetch(`${getVTUrl(env)}/ecommerce/transaction`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  });

  const transaction = await response.json();

  if (
    transaction?.status === "AUTHORIZED" ||
    transaction?.status === "CAPTURED" ||
    (transaction?.fundingSource?.type === "CHEQUE" && transaction?.status === "PENDING")
  ) {
    return transaction;
  }

  throw new Error(JSON.stringify(transaction));
}
