import { useEffect, useState } from "preact/hooks";
import { Fragment } from "preact";
import { route } from "preact-router";
import { Text } from "preact-i18n";

import SurchargeConfirmation from "../SurchargeConfirmation";
import PoyntCollectForm from "../PoyntCollectForm/PoyntCollectForm";
import FulfillmentForm from "../shared/FulfillmentForm";
import OrderSummary from "../shared/OrderSummary";
import TipsForm from "../shared/TipsForm";

import { isUnifiedOrder } from "../../helpers/order";
import {
  EMIT_LOAD_CATALOG_CHECKOUT,
  EMIT_LOAD_CHECKOUT,
  EMIT_LOAD_PRODUCT_CHECKOUT,
} from "../../helpers/event-definitions";

import "../../style/CheckoutPage.css";

const CheckoutPage = ({
  order,
  payLink,
  product,
  channels,
  setTransaction,
  setShowError,
  setErrorMsg,
  c2Analytics,
}) => {
  const [surchargeData, setSurchargeData] = useState(null);
  const [showSurchargeConfirmation, setShowSurchargeConfirmation] = useState(false);

  function backToCatalog() {
    route("/catalog");
  }

  function backToSelection() {
    if (isUnifiedOrder(payLink.order)) {
      route("/product/0");
    } else {
      route("/item/0");
    }
  }

  let header = (
    <h1 className="item-summary-header">
      <Text id="CHECKOUT_PAGE_TITLE">Order Summary</Text>
    </h1>
  );

  // track custom event on first load
  useEffect(() => {
    if (payLink.urlType === "I") {
      c2Analytics.trackCustomEvent(EMIT_LOAD_PRODUCT_CHECKOUT);
    } else if (isUnifiedOrder(payLink.order)) {
      c2Analytics.trackCustomEvent(EMIT_LOAD_CHECKOUT);
    } else {
      c2Analytics.trackCustomEvent(EMIT_LOAD_CATALOG_CHECKOUT);
    }
  }, [payLink]);

  // back buttons for catalog/selection
  if (payLink.urlType === "I" || !isUnifiedOrder(payLink.order)) {
    header = (
      <Fragment>
        <div className="item-summary-back">
          {(payLink.urlType === "I" || isUnifiedOrder(payLink.order)) ?
          <span onClick={backToSelection}>
            &lt; <Text id="ITEM_LAYOUT_BACK">Back</Text>
          </span> : 
          <span onClick={backToCatalog}>
            &lt; <Text id="ITEM_LAYOUT_BACK_TO_CATALOG">Back to Store</Text>
          </span>}
        </div>
        {header}
      </Fragment>
    );
  }

  return (
    <div>
      <div className="poynt-collect-form-checkout">
        <div className="checkout-page-order">
          <OrderSummary order={order} header={header} />
        </div>
        <div className={`poynt-collect-form-container ${showSurchargeConfirmation ? 'hidden' : ''}`}>
          <FulfillmentForm payLink={payLink} order={order} channels={channels} />
          <div className="container checkout-page-payment">
            <TipsForm payLink={payLink} order={order} />
            <PoyntCollectForm
              payLink={payLink}
              order={order}
              product={product}
              setTransaction={setTransaction}
              setShowError={setShowError}
              setErrorMsg={setErrorMsg}
              setSurchargeData={setSurchargeData}
              setShowSurchargeConfirmation={setShowSurchargeConfirmation}
            />
          </div>
        </div>
        {showSurchargeConfirmation ? (
          <div className='surcharge-container'>
            <SurchargeConfirmation
              setErrorMsg={setErrorMsg}
              setShowError={setShowError}
              surchargeData={surchargeData}
              setTransaction={setTransaction}
              setSurchargeData={setSurchargeData}
              setShowSurchargeConfirmation={setShowSurchargeConfirmation}
              trackCustomEvent={c2Analytics?.trackCustomEvent}
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default CheckoutPage;
