interface Address {
  addressLine1?: string;
  addressLine2?: string;
  addressLine3?: string;
  adminArea1?: string;
  adminArea2?: string;
  adminArea3?: string;
  adminArea4?: string;
  postalCode?: string;
  countryCode?: string;
}

export function formatAddress(address: Address) {
  return `${[
    address?.addressLine1,
    address?.addressLine2,
    address?.addressLine3,
    address?.adminArea2,
    address?.adminArea1,
  ]
    .filter((v) => v)
    .join(", ")}${address?.postalCode ? ` ${address?.postalCode}` : ""}`;
}
