import { DEFAULT_LOCALE, getFormattingOptions } from "./formatting-options";
import { getCurrencyOptions } from "./currency-options";

/**
 * @param {string} localeLang
 * @returns {string} locale formatted with correct case
 */
export const formatLocale = (localeLang: string) => {
  const [locale, country] = localeLang.split("-");

  return `${locale}-${country.toUpperCase()}`;
};

/**
 * @param {string} localeLang
 * @returns {string} merchant's two-letter ISO 3166 country code
 */
export const extractIsoFromLocale = (localeLang: string) => {
  const [locale, country] = localeLang.split("-");

  return country.toUpperCase();
};

/**
 * @param {string} amount The string value to be formatted such that in can be parsed an an int
 * @param {string} market The market in order to determine what elements need to be removed
 * @returns {string} Returns a string that can be parsed a number
 */
export const sanitizeAmountByMarket = (amount: string, market: string): string => {
  switch (market) {
    case "fr-ca":
      return amount.replace("$", "").replace(/\s/g, "").replace(",", ".");
    default:
      return amount.replace("$", "").replace(/,/g, "");
  }
};

/**
 * @param {number} value The initial value needing to be formatted
 * @param {string} currency The currency attached to the checkoutUrl ie: 'USD' 'CAD'
 * @param {string} marketId The marketId attached to the checkoutUrl ie: 'en-US' 'fr-CA'
 * @returns {string} Returns a locale-based formatted version of the initial value
 */
export const formatCurrencyString = (
  value: number = 0,
  currency: string = "USD",
  marketId: string,
) => {
  const market = marketId || formatLocale(DEFAULT_LOCALE);

  return new Intl.NumberFormat(market, { style: "currency", currency }).format(value);
};

/**
 * Formats an integer currency into a string
 * @param {number|string} value The initial value needing to be formatted
 * @param {string} currency The currency attached to the checkoutUrl ie: 'USD' 'CAD'
 * @param {string} marketId The marketId attached to the checkoutUrl ie: 'en-US' 'fr-CA'
 * @returns {string} Returns a locale-based formatted version of the initial value
 */
export function formatIntegerCurrencyString(
  value: number | string = 0,
  currency: string = "USD",
  marketId: string = "en-us",
) {
  const formattingOptions = getCurrencyOptions(currency);
  const multiplier = Math.pow(10, formattingOptions.digits);

  return formatCurrencyString(
    parseFloat((parseInt("" + value, 10) / multiplier).toFixed(formattingOptions.digits)),
    currency,
    marketId,
  );
}

/**
 * Formats an integer currency into a decimal string
 * @param {number|string} value The initial value needing to be formatted
 * @param {string} currency The currency attached to the checkoutUrl ie: 'USD' 'CAD'
 * @returns {string} Returns a decimal formatted string of the initial value
 */
export function formatIntegerCurrencyDecimal(value: number | string = 0, currency: string = "USD") {
  const formattingOptions = getCurrencyOptions(currency);
  const multiplier = Math.pow(10, formattingOptions.digits);

  return (parseInt("" + value, 10) / multiplier).toFixed(formattingOptions.digits);
}

/**
 * Formats a currency into an integer for server storage purposes
 * @param {number} value The initial value needing to be formatted
 * @param {string} currency The currency attached to the checkoutUrl ie: 'USD' 'CAD'
 * @returns {string} Returns a locale-based formatted version of the initial value
 */
export function formatCurrencyInteger(value: number, currency: string) {
  const formattingOptions = getCurrencyOptions(currency);
  const multiplier = Math.pow(10, formattingOptions.digits);

  return Math.round(parseFloat("" + value) * multiplier);
}
