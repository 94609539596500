import { getAPIUrl } from "../helpers/urls";

/**
 * Fetches channels for a store
 * @param {string} options.businessId
 * @param {string} options.storeId
 * @param {string} options.checkoutUrlId
 * @returns {Channel[]} channels
 */
export async function getChannels({ businessId, env, storeId, checkoutUrlId }) {
  const response = await fetch(
    `${getAPIUrl(env)}/api/e/businesses/${encodeURIComponent(
      businessId,
    )}/channels?checkoutUrlId=${encodeURIComponent(checkoutUrlId)}&storeId=${encodeURIComponent(
      storeId,
    )}`,
    {
      headers: {
        Accept: "application/json",
      },
    },
  );

  const data = await response?.json();

  return data?.channels;
}
