export enum FulfillmentMode {
  NONE = "NONE",
  PICKUP = "PICKUP",
  SHIP = "SHIP",
  CURBSIDE = "CURBSIDE",
  DELIVERY = "DELIVERY",
  DRIVE_THRU = "DRIVE_THRU",
  FOR_HERE = "FOR_HERE",
  TO_GO = "TO_GO",
  DIGITAL = "DIGITAL",
  GIFT_CARD = "GIFT_CARD",
  PURCHASE = "PURCHASE",
  GENERAL_CONTAINER = "GENERAL_CONTAINER",
  REGULAR_STAY = "REGULAR_STAY",
  QUICK_STAY = "QUICK_STAY",
  NON_LODGING_SALE = "NON_LODGING_SALE",
  NON_LODGING_NRR = "NON_LODGING_NRR",
}
