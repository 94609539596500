import { getAPIUrl } from "../../helpers/urls";

/**
 * Fetches taxes (or uses local version if not found)
 * @param {string} businessId
 * @param {Tax[]} taxes
 * @param {Tax[]} taxes
 */
export async function getTaxes({ businessId, env, taxes }) {
  return Promise.all(
    (taxes || []).map(async (tax) => {
      if (tax.id) {
        try {
          const response = await fetch(
            `${getAPIUrl(env)}/api/e/taxes/${encodeURIComponent(businessId)}/tax/${tax.id}`,
            {
              headers: {
                Accept: "application/json",
              },
            },
          );

          const taxJson = await response.json();

          if (taxJson.id === tax.id) {
            return taxJson;
          }
        } catch {
          // do nothing
        }
      }
      if (tax.amount !== undefined || tax.taxRatePercentage !== undefined) {
        return {
          name: tax.name || tax.type,
          type: tax.type,
          ...(tax.taxRatePercentage !== undefined
            ? {
                rate: tax.taxRatePercentage,
              }
            : {
                amount: tax.amount,
                amountPrecision: tax.amountPrecision,
              }),
        };
      }

      return;
    }),
  );
}
