import { useEffect } from "preact/hooks";
import { EMIT_LOAD_COMPLETE } from "../../helpers/event-definitions";

import ACHPaymentInitiatedSection from "./ACHPaymentInitiatedSection";
import PaymentSuccessfulSection from "./PaymentSuccessfulSection";
import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { useContext } from "preact/hooks";
import InvoiceAlreadyPaidSection from "./InvoiceAlreadyPaidSection";

const EndingPage = ({ transaction, isDesktopOrLaptop, c2Analytics, payLink }) => {
  // track custom event on first load
  useEffect(() => {
    if (c2Analytics?.trackCustomEvent) {
      c2Analytics.trackCustomEvent(EMIT_LOAD_COMPLETE);
    }
  }, []);

  const isACH = transaction?.fundingSource?.type === "CHEQUE";
  const configsContext = useContext(Configs);
  const isAlreadyPaidInvoice = payLink?.urlType === "N" && payLink?.invoice?.status === "PAID";
  const amount = formatIntegerCurrencyString(
    (isAlreadyPaidInvoice ? payLink?.amount : transaction?.processorResponse?.approvedAmount) || 0,
    configsContext?.configs?.currency,
    configsContext?.configs?.ecommerceLocaleLanguage,
  );
  const businessName = configsContext?.configs?.ecommerceBusinessName;
  const transactionId = isAlreadyPaidInvoice
    ? payLink?.invoice?.paidByTransactionId
    : transaction?.id;

  return isAlreadyPaidInvoice ? (
    <InvoiceAlreadyPaidSection
      transactionId={transactionId}
      isDesktopOrLaptop={isDesktopOrLaptop}
      amount={amount}
      businessName={businessName}
    />
  ) : isACH ? (
    <ACHPaymentInitiatedSection
      payLink={payLink}
      transactionId={transactionId}
      transaction={transaction}
      isDesktopOrLaptop={isDesktopOrLaptop}
      amount={amount}
      businessName={businessName}
    />
  ) : (
    <PaymentSuccessfulSection
      transaction={transaction}
      transactionId={transactionId}
      isDesktopOrLaptop={isDesktopOrLaptop}
      amount={amount}
      businessName={businessName}
    />
  );
};

export default EndingPage;
