import { signal, computed } from "@preact/signals";

/**
 * Creates a shipping line with proper signal bindings
 * @param {string} data.name (optional)
 * @param {string} data.requestedProvider (optional)
 * @param {string} data.requestedService (optional)
 * @param {number} data.totals.subTotal (optional)
 * @param {number} data.totals.taxTotal (optional)
 * @param {string} data.currency (optional)
 * @param {string} configs.currency (optional)
 * @returns
 */
export function createShippingLineSignal(data, configs) {
  const name = signal(data?.name || "");
  const requestedProvider = signal(data?.requestedProvider || "");
  const requestedService = signal(data?.requestedService || "");

  const subTotal = signal(data?.totals?.subTotal?.value || 0);
  const taxTotal = signal(data?.totals?.taxTotal?.value || 0);

  const currencyCode = configs?.currency || data?.currency || "USD";

  const shippingLine = computed(() => {
    const total = subTotal.value + taxTotal.value;

    const shippingLine = {
      name: name.value,
      amount: {
        value: total,
        currencyCode,
      },
      requestedProvider: requestedProvider.value,
      requestedService: requestedService.value,
      totals: {
        subTotal: {
          value: subTotal.value,
          currencyCode,
        },
        taxTotal: {
          value: taxTotal.value,
          currencyCode,
        },
      },
    };

    return shippingLine;
  });

  return { name, requestedProvider, requestedService, subTotal, taxTotal, shippingLine };
}
