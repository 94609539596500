import { getAPIUrl } from "../../helpers/urls";

/**
 * Fetches catalog
 * @param {string} options.businessId
 * @param {string} options.catalogId
 * @param {string} options.checkoutUrlId
 * @returns {Catalog} catalog
 */
export async function getCatalog({ businessId, env, storeId, catalogId, checkoutUrlId }) {
  const response = await fetch(
    `${getAPIUrl(env)}/api/e/businesses/${encodeURIComponent(
      businessId,
    )}/catalogs/${encodeURIComponent(catalogId)}?checkoutUrlId=${encodeURIComponent(
      checkoutUrlId,
    )}&storeId=${encodeURIComponent(storeId)}`,
    {
      headers: {
        Accept: "application/json",
      },
    },
  );

  return response.json();
}
