import { getVTUrl } from "../helpers/urls";

/**
 * Calculates surcharge fee
 * @param {string} env
 * @param {ChargeOptions} data
 */
export async function calculateSurchargeFee(env, data) {
  const response = await fetch(`${getVTUrl(env)}/ecommerce/calculate-fees`, {
    method: "post",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(data),
  });

  if (!response.ok) {
    throw new Error(`Error calculating surcharge fee: ${response.statusText}`);
  }

  const fees = await response.json();

  return (fees || []).find((fee) => fee.feeProgramType === "SURCHARGE");
}
