import { isUnifiedOrder } from "../helpers/order";

const PoyntHostList = [
  "local.poynt.dev-godaddy.com",
  "local.poynt.dev-secureserver.net",
  "local.poynt.net",
  "poynt.dev-godaddy.com",
  "poynt.dev-secureserver.net",
  "ci.poynt.net",
  "poynt.test-godaddy.com",
  "poynt.test-secureserver.net",
  "test.poynt.net",
  "poynt.ote-godaddy.com",
  "poynt.ote-secureserver.net",
  "ote.poynt.net",
  "poynt.godaddy.com",
  "poynt.secureserver.net",
  "poynt.net",
  "poynt-eu.godaddy.com",
  "poynt-eu.secureserver.net",
  "eu.poynt.net",
];

/**
 * Normalizes the `order` object in a pay link based on type
 * @param {PayLink} payLink
 */
export function normalizePayLinkOrder(payLink) {
  if (!payLink.order) {
    payLink.order = {};
  }

  if (isUnifiedOrder(payLink.order)) {
    return;
  }

  switch (payLink.urlType) {
    case "C":
    case "A":
    case "T":
      // for catalog/association types, should have empty items
      payLink.order.items = [];
      break;
    case "I":
      // for item type, pay link should have a single item w/ productId = to the paylink product
      const item = payLink.order.items?.[0];

      payLink.order.items = [
        {
          discount: 0,
          fee: 0,
          name: item?.name || payLink.title,
          productId: item?.productId || payLink.productId,
          quantity: 1,
          sku: item?.sku || payLink.productId || payLink.checkoutUrlId,
          status: item?.status || "FULFILLED",
          taxes: (item?.taxes || []).map((tax) => {
            return {
              amount: parseInt(tax.amount, 10),
              amountPrecision: parseInt(tax.amountPrecision, 10),
              id: tax.id,
              taxExempted: tax.taxExempted === true || tax.taxExempted === "true",
              type: tax.type,
            };
          }),
          tax: item?.tax ? parseInt(item?.tax, 10) : 0,
          taxExempted: item?.taxExempted === true || item?.taxExempted === "true",
          unitOfMeasure: "EACH",
          unitPrice: item?.unitPrice ? parseInt(item.unitPrice, 10) : payLink.amount,
        },
      ];
      break;
    case "N":
      // for invoice, fix bug where invoice creation isn't setting
      // item.taxes[], item.fees[], item.discounts[] arrays
      payLink.order?.items?.forEach((item) => {
        if (item.tax && !item.taxes?.length) {
          item.taxes = [
            {
              amount: item.tax * 10000,
              amountPrecision: 6,
              catalogLevel: true,
              id: payLink.order?.taxes?.[0]?.id,
              name: payLink.order?.taxes?.[0]?.name,
              taxExempted: false,
              type: payLink.order?.taxes?.[0]?.type,
              externalId: payLink.order?.taxes?.[0]?.externalId,
            },
          ];
        }

        if (item.discount && !item.discounts?.length) {
          item.discounts = [
            {
              amount: item.discount,
              name: "Discount",
            },
          ];
        }

        if (item.fee && !item.fees?.length) {
          item.fees = [
            {
              amount: item.fee,
              name: "Fee",
            },
          ];
        }
      });
      break;
    case "L":
      if (payLink.metadata?.options?.length) {
        // new multi select list (from payments hub)
        payLink.order.items = [
          {
            discount: 0,
            fee: 0,
            name: payLink.metadata.options[0]?.title || payLink.title,
            quantity: 1,
            sku: payLink.checkoutUrlId,
            status: "FULFILLED",
            tax: 0,
            taxExempted: false,
            unitOfMeasure: "EACH",
            unitPrice: payLink.metadata.options[0]?.amount || payLink.amount,
          },
        ];
      } else {
        // legacy multi select list (from poynt hq)
        payLink.order.items = [
          {
            discount: 0,
            fee: 0,
            name: payLink.title,
            quantity: 1,
            sku: payLink.checkoutUrlId,
            status: "FULFILLED",
            tax: 0,
            taxExempted: false,
            unitOfMeasure: "EACH",
            unitPrice: payLink.metadata.amountOptions?.[0] || payLink.amount,
          },
        ];
      }
      break;
    case "P":
    default:
      // for custom price or fixed price types, should have a single item
      // w/ no productId, and sku = checkoutUrlId
      payLink.order.items = [
        {
          discount: 0,
          fee: 0,
          name: payLink.title,
          quantity: 1,
          sku: payLink.checkoutUrlId,
          status: "FULFILLED",
          tax: 0,
          taxExempted: false,
          unitOfMeasure: "EACH",
          unitPrice: payLink.amount,
        },
      ];
      break;
  }
}

/**
 * Normalizes the `metadata` object in a pay link based on type
 * @param {PayLink} payLink
 */
export function normalizePayLinkMetadata(payLink) {
  if (!payLink.metadata) {
    payLink.metadata = {};
    return;
  }

  try {
    if (typeof payLink.metadata === "string") {
      payLink.metadata = JSON.parse(payLink.metadata);
    }
  } catch (_err) {
    payLink.metadata = {};
  }
}

/**
 * Gets the pay link and business/domain given current URL
 * @returns {string} businessId
 * @returns {string} domain
 * @returns {string} shortName
 * @returns {PayLink} payLink
 */
export async function loadPayLink() {
  let businessId;
  let shortName;
  let invoiceId;
  let domain;
  let payMyDomainNewFlow;
  let invoice;

  const params = new URLSearchParams(window.location.search);
  const path = window.location.pathname.split("/");
  const host = window.location.hostname;
  const split = host.split(".");
  const bid = split[0];

  if (bid && bid.match(/[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{12}/)) {
    // pay link eg {businessId}.paylinks.godaddy.com/{shortName}}
    // check if the split headers is in the format of {businessId}.paylinks.*-godaddy.com
    // pathName will look like: paylinks.*-godaddy.com/${shortName}
    payMyDomainNewFlow = true;
    businessId = bid;
    shortName = path[1] || null;
  } else if (!PoyntHostList.includes(host)) {
    // payable domain
    domain = host;

    if (path[1] === "i" && path[2]) {
      // invoices at pay.{domain}/i/{invoiceId}
      invoice = true;
      invoiceId = path[2];
    } else {
      // regular pay links at pay.{domain}/{shortName?}
      shortName = path[1] || "";
    }
  } else if (!path[1]) {
    // paylinks.commerce.godaddy.com itself doesn't resolve to anything
    // do nothing here
    businessId = null;
    shortName = null;
  } else {
    // url is gonna look like poynt.net/checkout/:bid/:shortName

    if (path[2] === "i" && path[3]) {
      // invoices at poynt.net/checkout/i/{invoiceId}
      invoice = true;
      invoiceId = path[3];
    } else {
      // pay links otherwise
      businessId = path[2] || "";
      shortName = path[3] || ""; // check to make sure this doesnt fail in null check
    }
  }

  let payLink;

  if (window?.payLink?.checkoutUrlId) {
    // load from page cache
    payLink = window.payLink;
  } else {
    let url;

    if (invoice) {
      url = `/api/b/checkout-urls/invoice/${invoiceId}`;
    } else if (payMyDomainNewFlow) {
      url = `/api/paylinks/${shortName}?${params.toString()}`;
    } else if (shortName && businessId) {
      url = `/api/b/checkout-urls/name/${businessId}/${shortName}?${params.toString()}`;
    } else if (!shortName && !businessId) {
      url = `/api/paylinks/default?${params.toString()}`;
    } else if (shortName && !businessId) {
      url = `/api/paylinks/${shortName}?${params.toString()}`;
    }

    // fetch pay link data
    const res = await fetch(url, {
      headers: {
        Accept: "application/json",
      },
    });
    payLink = await res.json();
  }

  // put this before normalizePayLinkOrder
  normalizePayLinkMetadata(payLink);

  normalizePayLinkOrder(payLink);

  if (domain) {
    payLink.domain = domain;
  }

  return payLink;
}
