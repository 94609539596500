import { Fragment } from "preact";

import LegacyOrderSummaryItem from "./LegacyOrderSummaryItem";
import OrderSummaryAmounts from "./OrderSummaryAmounts";
import CentralOrderSummary from "./CentralOrderSummary";

import "../../style/OrderSummary.css";

const OrderSummary = ({ order, header }) => {
  return (
    <div className="container order-summary">
      {header}
      {order?.isLegacy ? (
        <Fragment>
          <div class="order-summary-divider"></div>
          {order?.notes?.value ? (
            <Fragment>
              <div className="order-notes">{order.notes.value}</div>
              <div class="order-summary-divider"></div>
            </Fragment>
          ) : null}
          <div>
            {order?.order?.value?.items?.map((item) => {
              return <LegacyOrderSummaryItem item={item} />;
            })}
            <div class="order-summary-divider"></div>
          </div>
          <OrderSummaryAmounts
            discountTotal={order?.order?.value?.amounts?.discountTotal}
            feeTotal={order?.order?.value?.amounts?.feeTotal}
            taxTotal={order?.order?.value?.amounts?.taxTotal}
            subTotal={order?.order?.value?.amounts?.subTotal}
            tipAmount={order?.order?.value?.amounts?.tipAmount}
            total={order?.order?.value?.amounts?.transactionAmount}
          />
        </Fragment>
      ) : (
        <CentralOrderSummary order={order}/>
      )}
    </div>
  );
};

export default OrderSummary;
