/**
 * Tells us if the button background color is light
 * @param {string} buttonColor
 * @returns
 */
export function isLightBackground(buttonColor) {
  if (buttonColor === "#F6CD3C") {
    return true;
  }
}

/**
 * Gets button style for selected option
 * @param {string} buttonColor
 * @returns
 */
export function getActionButtonStyle(buttonColor, defaultStyle) {
  let style = defaultStyle || "background: #2544b7;";

  if (buttonColor) {
    if (isLightBackground(buttonColor)) {
      style = "background: " + buttonColor + "; color: black;";
    } else {
      style = "background: " + buttonColor + "; color: white;";
    }
  }

  return style;
}
