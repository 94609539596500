import { Fragment } from "preact";
import { useEffect, useContext, useState } from "preact/hooks";
import { route } from "preact-router";
import { Text } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { getActionButtonStyle } from "../../helpers/button";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { initOrderItemWithProduct } from "../../helpers/order";
import { EMIT_LOAD_CATALOG_ADD_PRODUCT, EMIT_LOAD_PRODUCT } from "../../helpers/event-definitions";

import { createLegacyOrderItemSignal } from "../../signals/legacy/orderItem";

import SingleSelectModifier from "./SingleSelectModifier";
import MultiSelectModifier from "./MultiSelectModifier";

import "../../style/ProductPage.css";

const LegacyProductPage = ({ payLink, order, id, product, c2Analytics }) => {
  const [orderItem, setOrderItem] = useState(null);
  const [addProduct, setAddProduct] = useState(false);

  const configsContext = useContext(Configs);

  // initialize the order item that we'll be modifying here
  useEffect(() => {
    let item;

    if (id === "add") {
      setAddProduct(true);

      item = createLegacyOrderItemSignal({
        ...initOrderItemWithProduct(product),
        taxes: order?.order?.value?.taxes, // add order level taxes, if any
      });

      if (c2Analytics?.trackCustomEvent) {
        c2Analytics.trackCustomEvent(EMIT_LOAD_CATALOG_ADD_PRODUCT);
      }
    } else {
      item = order?.items?.value?.[id] ? order.items.value[id] : order?.lineItems?.value[id];

      if (c2Analytics?.trackCustomEvent) {
        c2Analytics.trackCustomEvent(EMIT_LOAD_PRODUCT);
      }
    }

    setOrderItem(item);
  }, [id, order, product]);

  function continueToPayment() {
    window?.parent?.postMessage(
      { sender: "pay-buttons-js", action: "setCheckoutContinue", value: true },
      "*",
    );

    route("/checkout");
  }

  function addToCart() {
    order.items.value = [...order.items.value, orderItem];

    route("/catalog");
  }

  function backToCatalog() {
    route("/catalog");
  }

  // modifiers section
  let selectableVariations = [];
  if (product?.selectableVariants?.[0].selectableVariations?.length) {
    product?.selectableVariants?.[0].selectableVariations.forEach((variation, i) => {
      if (variation.cardinality === "1") {
        // dropdown modifier
        selectableVariations.push(
          <SingleSelectModifier variation={variation} orderItem={orderItem} />,
        );
      } else {
        // cardinality is 0..*
        // multiple select checkboxes
        selectableVariations.push(
          <MultiSelectModifier variation={variation} orderItem={orderItem} />,
        );
      }
    });
  }

  // CTA button
  const actionButtonStyle = getActionButtonStyle(
    configsContext?.configs?.ecommerceCheckoutButtonColor,
  );

  // class to attach to entire form if no picture is present
  const pc = payLink.picture ? "" : "no-picture";

  let actionButton;
  if (payLink.urlType === "I") {
    // if it's an Item type pay link, then CTA is pay
    actionButton = (
      <div
        className={`item-layout-continue ${pc}`}
        role="button"
        onClick={continueToPayment}
        style={actionButtonStyle}
      >
        <span className="item-layout-continue-text">
          <Text id="ITEM_LAYOUT_CONTINUE_TEXT">Continue to Payment</Text>
        </span>
      </div>
    );
  } else {
    // else we need to add to cart
    actionButton = (
      <Fragment>
        <div className={`item-layout-continue ${pc}`} onClick={addToCart} style={actionButtonStyle}>
          <span className="item-layout-continue-text">
            <Text id="ITEM_LAYOUT_ADD_TO_CART">Add to Cart</Text>
          </span>
        </div>
        <div className={`item-layout-back ${pc}`} onClick={backToCatalog}>
          <span className="item-layout-continue-text">
            <Text id="ITEM_LAYOUT_BACK">Back</Text>
          </span>
        </div>
      </Fragment>
    );
  }

  const productForm = orderItem ? (
    <Fragment>
      <h1 className={`item-layout-title ${pc}`}>{addProduct ? product.name : payLink.title}</h1>
      <h1 className={`item-layout-amount ${pc}`}>
        {formatIntegerCurrencyString(
          orderItem.unitPrice,
          payLink.currency,
          configsContext?.configs?.ecommerceLocaleLanguage,
        )}
      </h1>
      <p className={`item-layout-description ${pc}`}>
        {addProduct ? product.description : payLink.description}
      </p>
      <div className={`item-modifiers-section ${pc}`}>
        <span className="item-single-select-modifiers">{selectableVariations}</span>
      </div>
      <div className={`item-layout-cart-size ${pc}`}>
        <div
          className="item-layout-decrement-counter row ._25"
          role="button"
          onClick={() => {
            orderItem.quantity.value = Math.max(1, orderItem.quantity.value - 1);
          }}
        >
          <span className="item-layout-decrement-text">-</span>
        </div>
        <div className="item-layout-counter row ._50">
          <span className="item-layout-counter-text">{orderItem.quantity.value}</span>
        </div>
        <div
          className="item-layout-increment-counter row ._25"
          role="button"
          onClick={() => {
            orderItem.quantity.value = orderItem.quantity.value + 1;
          }}
        >
          <span className="item-layout-increment-text">+</span>
        </div>
      </div>
      {actionButton}
    </Fragment>
  ) : null;

  const picture = addProduct ? product.imageUrl?.[0] : payLink.picture;

  if (picture) {
    return (
      <div className="container item-layout-section-with-picture">
        <div className="item-picture">
          <img className="item-pic" src={picture} />
        </div>
        <div className="item-description-container">{productForm}</div>
      </div>
    );
  } else {
    // no picture
    return <div className="container item-layout-section">{productForm}</div>;
  }
};

export default LegacyProductPage;
