const CARD_LOGOS = {
  AMERICAN_EXPRESS: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/AMEX.svg",
  EBT: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/icon-card-ebt.svg",
  BANCOMAT: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/icon-card-bancomat.svg",
  DISCOVER: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Discover.svg",
  MAESTRO: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Maestro.svg",
  GOPAY: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Gopay.svg",
  DINERS_CLUB: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Dinersclub.svg",
  JCB: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/JCB.svg",
  ALIPAY: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Alipay.svg",
  MASTERCARD: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Mastercard.svg",
  DANKORT: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Dankort.svg",
  OTHER: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Other.svg",
  PAYPAL: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Paypal.svg",
  INTERAC: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/icon-card-interac.svg",
  UNIONPAY: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Unionpay.svg",
  VISA: "https://d85ecz8votkqa.cloudfront.net/ds/transactions/Visa.svg",
};

export const getCardLogo = (cardType = '') => {
  return CARD_LOGOS[cardType.toUpperCase()] || CARD_LOGOS.OTHER;
};
