interface CurrencyOptions {
  name: string;
  digits: number;
  defaultLocale: string;
}

const currencyOptions: { [key: string]: CurrencyOptions } = {};

// currencyOptions.AED = {
//   name: "United Arab Emirates Dirham",
//   digits: 2,
//   defaultLocale: "ar-AE",
// };

// currencyOptions.AFN = {
//   name: "Afghan Afghani",
//   digits: 2,
//   defaultLocale: "fa-AF",
// };

// currencyOptions.ALL = {
//   name: "Albanian Lek",
//   digits: 2,
//   defaultLocale: "sq-AL",
// };

// currencyOptions.AMD = {
//   name: "Armenian Dram",
//   digits: 2,
//   defaultLocale: "hy-AM",
// };

// currencyOptions.ANG = {
//   name: "Netherlands Antillean Guilder",
//   digits: 2,
//   defaultLocale: "nl-CW",
// };

// currencyOptions.AOA = {
//   name: "Angolan Kwanza",
//   digits: 2,
//   defaultLocale: "pt-AO",
// };

// currencyOptions.ARS = {
//   name: "Argentine Peso",
//   digits: 2,
//   defaultLocale: "es-AR",
// };

// currencyOptions.AUD = {
//   name: "Australian Dollar",
//   digits: 2,
//   defaultLocale: "en-AU",
// };

// currencyOptions.AWG = {
//   name: "Aruban Florin",
//   digits: 2,
//   defaultLocale: "nl-AW",
// };

// currencyOptions.AZN = {
//   name: "Azerbaijani Manat",
//   digits: 2,
//   defaultLocale: "az-AZ",
// };

// currencyOptions.BAM = {
//   name: "Bosnia and Herzegovina Convertible Mark",
//   digits: 2,
//   defaultLocale: "bs-BA",
// };

// currencyOptions.BBD = {
//   name: "Barbados Dollar",
//   digits: 2,
//   defaultLocale: "en-BB",
// };

// currencyOptions.BDT = {
//   name: "Bangladeshi Taka",
//   digits: 2,
//   defaultLocale: "bn-BD",
// };

// currencyOptions.BGN = {
//   name: "Bulgarian Lev",
//   digits: 2,
//   defaultLocale: "bg-BG",
// };

// currencyOptions.BHD = {
//   name: "Bahraini Dinar",
//   digits: 3,
//   defaultLocale: "ar-BH",
// };

// currencyOptions.BIF = {
//   name: "Burundian Franc",
//   digits: 0,
//   defaultLocale: "fr-BI",
// };

// currencyOptions.BMD = {
//   name: "Bermudian Dollar",
//   digits: 2,
//   defaultLocale: "en-BM",
// };

// currencyOptions.BND = {
//   name: "Brunei Dollar",
//   digits: 2,
//   defaultLocale: "ms-BN",
// };

// currencyOptions.BOB = {
//   name: "Boliviano",
//   digits: 2,
//   defaultLocale: "es-BO",
// };

// currencyOptions.BOV = {
//   name: "Bolivian Mvdol",
//   digits: 2,
//   defaultLocale: "es-BO",
// };

// currencyOptions.BRL = {
//   name: "Brazilian Real",
//   digits: 2,
//   defaultLocale: "pt-BR",
// };

// currencyOptions.BSD = {
//   name: "Bahamian Dollar",
//   digits: 2,
//   defaultLocale: "en-BS",
// };

// currencyOptions.BTN = {
//   name: "Bhutanese Ngultrum",
//   digits: 2,
//   defaultLocale: "dz-BT",
// };

// currencyOptions.BWP = {
//   name: "Botswana Pula",
//   digits: 2,
//   defaultLocale: "en-BW",
// };

// currencyOptions.BYN = {
//   name: "Belarusian Ruble",
//   digits: 2,
//   defaultLocale: "be-BY",
// };

// currencyOptions.BZD = {
//   name: "Belize Dollar",
//   digits: 2,
//   defaultLocale: "en-BZ",
// };

currencyOptions.CAD = {
  name: "Canadian Dollar",
  digits: 2,
  defaultLocale: "en-CA",
};

// currencyOptions.CDF = {
//   name: "Congolese franc",
//   digits: 2,
//   defaultLocale: "fr-CG",
// };

// currencyOptions.CHE = {
//   name: "WIR Euro",
//   digits: 2,
//   defaultLocale: "de-CH",
// };

// currencyOptions.CHF = {
//   name: "Swiss Franc",
//   digits: 2,
//   defaultLocale: "de-CH",
// };

// currencyOptions.CHW = {
//   name: "WIR Franc",
//   digits: 2,
//   defaultLocale: "de-CH",
// };

// currencyOptions.CLF = {
//   name: "Unidad de Fomento",
//   digits: 2,
//   defaultLocale: "es-CL",
// };

// currencyOptions.CLP = {
//   name: "Chilean Peso",
//   digits: 0,
//   defaultLocale: "es-CL",
// };

// currencyOptions.CNY = {
//   name: "Chinese Yuan",
//   digits: 2,
//   defaultLocale: "zh-CN",
// };

// currencyOptions.COP = {
//   name: "Colombian Peso",
//   digits: 2,
//   defaultLocale: "es-CO",
// };

// currencyOptions.COU = {
//   name: "Unidad de Valor Real",
//   digits: 2,
//   defaultLocale: "es-CO",
// };

// currencyOptions.CRC = {
//   name: "Costa Rican Colon",
//   digits: 2,
//   defaultLocale: "es-CR",
// };

// currencyOptions.CUC = {
//   name: "Cuban Convertible Peso",
//   digits: 2,
//   defaultLocale: "es-CU",
// };

// currencyOptions.CUP = {
//   name: "Cuban Peso",
//   digits: 2,
//   defaultLocale: "es-CU",
// };

// currencyOptions.CVE = {
//   name: "Cape Verde Escudo",
//   digits: 0,
//   defaultLocale: "kea-CV",
// };

// currencyOptions.CZK = {
//   name: "Czech Koruna",
//   digits: 2,
//   defaultLocale: "cs-CZ",
// };

// currencyOptions.DJF = {
//   name: "Djiboutian Franc",
//   digits: 0,
//   defaultLocale: "so-DJ",
// };

// currencyOptions.DKK = {
//   name: "Danish Krone",
//   digits: 2,
//   defaultLocale: "da-DK",
// };

// currencyOptions.DOP = {
//   name: "Dominican Peso",
//   digits: 2,
//   defaultLocale: "en-DM",
// };

// currencyOptions.DZD = {
//   name: "Algerian Dinar",
//   digits: 2,
//   defaultLocale: "ar-DZ",
// };

// currencyOptions.EGP = {
//   name: "Egyptian Pound",
//   digits: 2,
//   defaultLocale: "ar-EG",
// };

// currencyOptions.ERN = {
//   name: "Eritrean Nakfa",
//   digits: 2,
//   defaultLocale: "ti-ER",
// };

// currencyOptions.ETB = {
//   name: "Ethiopian Birr",
//   digits: 2,
//   defaultLocale: "so-ET",
// };

// currencyOptions.EUR = {
//   name: "Euro",
//   digits: 2,
//   defaultLocale: "", // varies within countries, this will be handled based on card
// };

// currencyOptions.FJD = {
//   name: "Fiji Dollar",
//   digits: 2,
//   defaultLocale: "en-FJ",
// };

// currencyOptions.FKP = {
//   name: "Falkland Islands Pound",
//   digits: 2,
//   defaultLocale: "en-FK",
// };

// currencyOptions.GBP = {
//   name: "British Pound Sterling",
//   digits: 2,
//   defaultLocale: "en-GB",
// };

// currencyOptions.GEL = {
//   name: "Georgian Lari",
//   digits: 2,
//   defaultLocale: "ka-GE",
// };

// currencyOptions.GHS = {
//   name: "Ghanaian Cedi",
//   digits: 2,
//   defaultLocale: "ee-GH",
// };

// currencyOptions.GIP = {
//   name: "Gibraltar Pound",
//   digits: 2,
//   defaultLocale: "en-GI",
// };

// currencyOptions.GMD = {
//   name: "Gambian Dalasi",
//   digits: 2,
//   defaultLocale: "en-GM",
// };

// currencyOptions.GNF = {
//   name: "Guinean Franc",
//   digits: 0,
//   defaultLocale: "fr-GN",
// };

// currencyOptions.GTQ = {
//   name: "Guatemalan Quetzal",
//   digits: 2,
//   defaultLocale: "es-GT",
// };

// currencyOptions.GYD = {
//   name: "Guyanese Dollar",
//   digits: 2,
//   defaultLocale: "en-GY",
// };

// currencyOptions.HKD = {
//   name: "Hong Kong Dollar",
//   digits: 2,
//   defaultLocale: "en-HK",
// };

// currencyOptions.HNL = {
//   name: "Honduran Lempira",
//   digits: 2,
//   defaultLocale: "es-HN",
// };

// currencyOptions.HRK = {
//   name: "Croatian Kuna",
//   digits: 2,
//   defaultLocale: "hr-HR",
// };

// currencyOptions.HTG = {
//   name: "Haitian Gourde",
//   digits: 2,
//   defaultLocale: "ht-HT",
// };

// currencyOptions.HUF = {
//   name: "Hungarian Forint",
//   digits: 2,
//   defaultLocale: "hu-HU",
// };

// currencyOptions.IDR = {
//   name: "Indonesian Rupiah",
//   digits: 2,
//   defaultLocale: "id-ID",
// };

// currencyOptions.ILS = {
//   name: "Israeli New Shekel",
//   digits: 2,
//   defaultLocale: "he-IL",
// };

// currencyOptions.INR = {
//   name: "Indian Rupee",
//   digits: 2,
//   defaultLocale: "en-IN",
// };

// currencyOptions.IQD = {
//   name: "Iraqi Dinar",
//   digits: 3,
//   defaultLocale: "ar-IQ",
// };

// currencyOptions.IRR = {
//   name: "Iranian Rial",
//   digits: 2,
//   defaultLocale: "fa-IR",
// };

// currencyOptions.ISK = {
//   name: "Icelandic Króna",
//   digits: 2,
//   defaultLocale: "is-IS",
// };

// currencyOptions.JMD = {
//   name: "Jamaican Dollar",
//   digits: 2,
//   defaultLocale: "en-JM",
// };

// currencyOptions.JOD = {
//   name: "Jordanian Dinar",
//   digits: 3,
//   defaultLocale: "ar-JO",
// };

// currencyOptions.JPY = {
//   name: "Japanese Yen",
//   digits: 0,
//   defaultLocale: "ja-JP",
// };

// currencyOptions.KES = {
//   name: "Kenyan Shilling",
//   digits: 2,
//   defaultLocale: "sw-KE",
// };

// currencyOptions.KGS = {
//   name: "Kyrgyzstani Som",
//   digits: 2,
//   defaultLocale: "ky-KG",
// };

// currencyOptions.KHR = {
//   name: "Cambodian Riel",
//   digits: 2,
//   defaultLocale: "km-KH",
// };

// currencyOptions.KMF = {
//   name: "Comoro Franc",
//   digits: 0,
//   defaultLocale: "fr-KM",
// };

// currencyOptions.KPW = {
//   name: "North Korean Won",
//   digits: 2,
//   defaultLocale: "ko-KP",
// };

// currencyOptions.KRW = {
//   name: "South Korean Won",
//   digits: 0,
//   defaultLocale: "ko-KR",
// };

// currencyOptions.KWD = {
//   name: "Kuwaiti Dinar",
//   digits: 3,
//   defaultLocale: "ko-KR",
// };

// currencyOptions.KYD = {
//   name: "Cayman Islands Dollar",
//   digits: 2,
//   defaultLocale: "ky",
// };

// currencyOptions.KZT = {
//   name: "Kazakhstani Tenge",
//   digits: 2,
//   defaultLocale: "kk-KZ",
// };

// currencyOptions.LAK = {
//   name: "Lao Kip",
//   digits: 2,
//   defaultLocale: "lo-LA",
// };

// currencyOptions.LBP = {
//   name: "Lebanese Pound",
//   digits: 2,
//   defaultLocale: "ar-LB",
// };

// currencyOptions.LKR = {
//   name: "Sri Lankan Rupee",
//   digits: 2,
//   defaultLocale: "si-LK",
// };

// currencyOptions.LRD = {
//   name: "Liberian Dollar",
//   digits: 2,
//   defaultLocale: "si-LK",
// };

// currencyOptions.LSL = {
//   name: "Sri Lankan Rupee",
//   digits: 2,
//   defaultLocale: "vai-Vaii-LR",
// };

// currencyOptions.LYD = {
//   name: "Libyan Dinar",
//   digits: 2,
//   defaultLocale: "ar-LY",
// };

// currencyOptions.MAD = {
//   name: "Moroccan Dirham",
//   digits: 2,
//   defaultLocale: "ar-MA",
// };

// currencyOptions.MDL = {
//   name: "Moldovan Leu",
//   digits: 2,
//   defaultLocale: "ru-MD",
// };

// currencyOptions.MGA = {
//   name: "Malagasy Ariary",
//   digits: 2,
//   defaultLocale: "mg-MG",
// };

// currencyOptions.MKD = {
//   name: "Macedonian Denar",
//   digits: 2,
//   defaultLocale: "mk-MK",
// };

// currencyOptions.MMK = {
//   name: "Myanmar Kyat",
//   digits: 2,
//   defaultLocale: "my-MM",
// };

// currencyOptions.MNT = {
//   name: "Mongolian Tögrög",
//   digits: 2,
//   defaultLocale: "mn-MN",
// };

// currencyOptions.MOP = {
//   name: "Macanese Pataca",
//   digits: 2,
//   defaultLocale: "zh-MO",
// };

// currencyOptions.MUR = {
//   name: "Mauritian Rupee",
//   digits: 2,
//   defaultLocale: "mfe-MU",
// };

// currencyOptions.MVR = {
//   name: "Maldivian Rufiyaa",
//   digits: 2,
//   defaultLocale: "dv-MV",
// };

// currencyOptions.MWK = {
//   name: "Malawian Kwacha",
//   digits: 2,
//   defaultLocale: "en-MW",
// };

// currencyOptions.MXN = {
//   name: "Mexican Peso",
//   digits: 2,
//   defaultLocale: "es-MX",
// };

// currencyOptions.MXV = {
//   name: "Mexican Unidad de Inversion",
//   digits: 2,
//   defaultLocale: "es-MX",
// };

// currencyOptions.MYR = {
//   name: "Malaysian Ringgit",
//   digits: 2,
//   defaultLocale: "ms-MY",
// };

// currencyOptions.MZN = {
//   name: "Mozambican metical",
//   digits: 2,
//   defaultLocale: "pt-MZ",
// };

// currencyOptions.NAD = {
//   name: "Namibian Dollar",
//   digits: 2,
//   defaultLocale: "af-NA",
// };

// currencyOptions.NGN = {
//   name: "Nigerian Naira",
//   digits: 2,
//   defaultLocale: "en-NG",
// };

// currencyOptions.NIO = {
//   name: "Nicaraguan Córdoba",
//   digits: 2,
//   defaultLocale: "es-NI",
// };

// currencyOptions.NOK = {
//   name: "Norwegian Krone",
//   digits: 2,
//   defaultLocale: "no-NO",
// };

// currencyOptions.NPR = {
//   name: "Nepalese Rupee",
//   digits: 2,
//   defaultLocale: "ne-NP",
// };

// currencyOptions.NZD = {
//   name: "New Zealand Dollar",
//   digits: 2,
//   defaultLocale: "en-NZ",
// };

// currencyOptions.OMR = {
//   name: "Omani Rial",
//   digits: 3,
//   defaultLocale: "ar-OM",
// };

// currencyOptions.PAB = {
//   name: "Panamanian Balboa",
//   digits: 2,
//   defaultLocale: "es-PA",
// };

// currencyOptions.PEN = {
//   name: "Peruvian Sol",
//   digits: 2,
//   defaultLocale: "es-PE",
// };

// currencyOptions.PGK = {
//   name: "Papua New Guinean Kina",
//   digits: 2,
//   defaultLocale: "yuw-PG",
// };

// currencyOptions.PHP = {
//   name: "Philippine Piso",
//   digits: 2,
//   defaultLocale: "en-PH",
// };

// currencyOptions.PKR = {
//   name: "Pakistani Rupee",
//   digits: 2,
//   defaultLocale: "ur-PK",
// };

// currencyOptions.PLN = {
//   name: "Polish Złoty",
//   digits: 2,
//   defaultLocale: "pl-PL",
// };

// currencyOptions.PYG = {
//   name: "Paraguayan Guaraní",
//   digits: 0,
//   defaultLocale: "es-PY",
// };

// currencyOptions.QAR = {
//   name: "Qatari Riyal",
//   digits: 2,
//   defaultLocale: "ar-QA",
// };

// currencyOptions.RON = {
//   name: "Romanian Leu",
//   digits: 2,
//   defaultLocale: "ro-RO",
// };

// currencyOptions.RSD = {
//   name: "Serbian Dinar",
//   digits: 2,
//   defaultLocale: "ro-RO",
// };

// currencyOptions.RUB = {
//   name: "Russian Ruble",
//   digits: 2,
//   defaultLocale: "ru-RU",
// };

// currencyOptions.RWF = {
//   name: "Rwandan Franc",
//   digits: 0,
//   defaultLocale: "rw-RW",
// };

// currencyOptions.SAR = {
//   name: "Saudi Riyal",
//   digits: 2,
//   defaultLocale: "ar-SA",
// };

// currencyOptions.SBD = {
//   name: "Solomon Islands Dollar",
//   digits: 2,
//   defaultLocale: "en-SB",
// };

// currencyOptions.SCR = {
//   name: "Seychelles Rupee",
//   digits: 2,
//   defaultLocale: "en-SC",
// };

// currencyOptions.SDG = {
//   name: "Sudanese Pound",
//   digits: 2,
//   defaultLocale: "ar-SD",
// };

// currencyOptions.SEK = {
//   name: "Swedish Krona",
//   digits: 2,
//   defaultLocale: "sv-SE",
// };

// currencyOptions.SGD = {
//   name: "Singapore Dollar",
//   digits: 2,
//   defaultLocale: "en-SG",
// };

// currencyOptions.SHP = {
//   name: "Saint Helena Pound",
//   digits: 2,
//   defaultLocale: "en-SH",
// };

// currencyOptions.SLL = {
//   name: "Sierra Leonean Leone",
//   digits: 2,
//   defaultLocale: "en-SL",
// };

// currencyOptions.SOS = {
//   name: "Somali Shilling",
//   digits: 2,
//   defaultLocale: "so-SO",
// };

// currencyOptions.SRD = {
//   name: "Surinamese Dollar",
//   digits: 2,
//   defaultLocale: "nl-SR",
// };

// currencyOptions.SSP = {
//   name: "South Sudanese Pound",
//   digits: 2,
//   defaultLocale: "en-SS",
// };

// currencyOptions.STN = {
//   name: "São Tomé and Príncipe Dobra",
//   digits: 2,
//   defaultLocale: "pt-ST",
// };

// currencyOptions.SVC = {
//   name: "Salvadoran Colón",
//   digits: 2,
//   defaultLocale: "es-SV",
// };

// currencyOptions.SYP = {
//   name: "Syrian Pound",
//   digits: 2,
//   defaultLocale: "ar-SY",
// };

// currencyOptions.SZL = {
//   name: "Swazi Lilangeni",
//   digits: 2,
//   defaultLocale: "en-SZ",
// };

// currencyOptions.THB = {
//   name: "Thai Baht",
//   digits: 2,
//   defaultLocale: "th-TH",
// };

// currencyOptions.TJS = {
//   name: "Tajikistani Somoni",
//   digits: 2,
//   defaultLocale: "tg-TJ",
// };

// currencyOptions.TMT = {
//   name: "Turkmenistan Manat",
//   digits: 2,
//   defaultLocale: "tk-TM",
// };

// currencyOptions.TND = {
//   name: "Tunisian dinar",
//   digits: 3,
//   defaultLocale: "ar-TN",
// };

// currencyOptions.TOP = {
//   name: "Tongan Paʻanga",
//   digits: 2,
//   defaultLocale: "to-TO",
// };

// currencyOptions.TRY = {
//   name: "Turkish Lira",
//   digits: 2,
//   defaultLocale: "tr-TR",
// };

// currencyOptions.TTD = {
//   name: "Trinidad and Tobago Dollar",
//   digits: 2,
//   defaultLocale: "en-TT",
// };

// currencyOptions.TWD = {
//   name: "New Taiwan Dollar",
//   digits: 2,
//   defaultLocale: "zh-TW",
// };

// currencyOptions.TZS = {
//   name: "Tanzanian Shilling",
//   digits: 2,
//   defaultLocale: "sw-TZ",
// };

// currencyOptions.UAH = {
//   name: "Ukrainian Hryvnia",
//   digits: 2,
//   defaultLocale: "uk-UA",
// };
// currencyOptions.UGX = {
//   name: "Tanzanian Shilling",
//   digits: 0,
//   defaultLocale: "sw-TZ",
// };

currencyOptions.USD = {
  name: "United States Dollar",
  digits: 2,
  defaultLocale: "en-US",
};

// currencyOptions.USN = {
//   name: "United States Dollar",
//   digits: 2,
//   defaultLocale: "en-US",
// };

// currencyOptions.UYI = {
//   name: "Uruguay Peso en Unidades Indexadas",
//   digits: 0,
//   defaultLocale: "es-UY",
// };

// currencyOptions.UYU = {
//   name: "Uruguayan Peso",
//   digits: 2,
//   defaultLocale: "es-UY",
// };

// currencyOptions.UZS = {
//   name: "Uzbekistan Som",
//   digits: 2,
//   defaultLocale: "uz-Latn-UZ",
// };

// currencyOptions.VEF = {
//   name: "Venezuelan Bolívar",
//   digits: 2,
//   defaultLocale: "es-VE",
// };

// currencyOptions.VND = {
//   name: "Vietnamese đồng",
//   digits: 0,
//   defaultLocale: "vi-VN",
// };

// currencyOptions.VUV = {
//   name: "Vanuatu Vatu",
//   digits: 0,
//   defaultLocale: "en-VU",
// };

// currencyOptions.WST = {
//   name: "Samoan Tala",
//   digits: 2,
//   defaultLocale: "en-AS",
// };

// currencyOptions.XAF = {
//   name: "Central African CFA Franc",
//   digits: 0,
//   defaultLocale: "", // varies within countries, this will be handled based on card
// };

// currencyOptions.XCD = {
//   name: "East Caribbean Dollar",
//   digits: 2,
//   defaultLocale: "", // varies within countries, this will be handled based on card
// };

// currencyOptions.XOF = {
//   name: "West African CFA Franc",
//   digits: 0,
//   defaultLocale: "", // varies within countries, this will be handled based on card
// };

// currencyOptions.XPF = {
//   name: "CFP Franc",
//   digits: 0,
//   defaultLocale: "", // varies within countries, this will be handled based on card
// };

// currencyOptions.YER = {
//   name: "Yemeni Rial",
//   digits: 2,
//   defaultLocale: "ar-YE",
// };

// currencyOptions.ZAR = {
//   name: "South African Rand",
//   digits: 2,
//   defaultLocale: "en-ZA",
// };

// currencyOptions.ZMW = {
//   name: "Zambian Kwacha",
//   digits: 2,
//   defaultLocale: "bem-ZM",
// };

// currencyOptions.ZWL = {
//   name: "Zimbabwean Dollar",
//   digits: 2,
//   defaultLocale: "en-ZW",
// };

/**
 * Gets currency options per currency; defaults to USD
 * @param {string} currency
 * @returns {CurrencyOptions} formattingOptions
 */
export function getCurrencyOptions(currency) {
  return currencyOptions[currency] || currencyOptions.USD;
}

export default { currencyOptions, getCurrencyOptions };
