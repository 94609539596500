interface ShowApplePayButtonResponse {
  showApplePay: boolean;
}

const PoyntHostList = [
  "local.poynt.net",
  "ci.poynt.net",
  "test.poynt.net",
  "ote.poynt.net",
  "poynt.net",
  "eu.poynt.net",
];

const GdHostList = [
  "local.poynt.dev-godaddy.com",
  "local.poynt.dev-secureserver.net",
  "poynt.dev-godaddy.com",
  "poynt.dev-secureserver.net",
  "poynt.test-godaddy.com",
  "poynt.test-secureserver.net",
  "poynt.ote-godaddy.com",
  "poynt.ote-secureserver.net",
  "poynt.godaddy.com",
  "poynt.secureserver.net",
  "poynt-eu.godaddy.com",
  "poynt-eu.secureserver.net",
];

function getEnvHost(env: string) {
  const host = window.location.hostname;

  if (PoyntHostList.includes(host) || GdHostList.includes(host)) {
    return host;
  } else {
    if (env === "development" || env === "dev-private") {
      return "local.poynt.dev-godaddy.com";
    } else if (env === "ci") {
      return "poynt.dev-godaddy.com";
    } else if (env === "ote") {
      return "poynt.ote-godaddy.com";
    } else if (env === "test" || env === "st") {
      return "poynt.test-godaddy.com";
    } else {
      return "poynt.godaddy.com";
    }
  }
}

export async function showApplePayButton(
  env: string,
  businessId: string,
): Promise<ShowApplePayButtonResponse> {
  try {
    const res = await fetch(
      `https://${getEnvHost(env)}/api/b/checkout-urls/${businessId}/apple-pay/merchant`,
    );

    const response = await res.json();

    if (response && response.domains && response.domains.includes(window.location.hostname)) {
      return { showApplePay: true };
    }
  } catch (error) {
    console.error("Failed to retrieve merchant: ", error);
  }

  return { showApplePay: false };
}
