export const getBillingAddresses = (isRequired: boolean, ) => {
    return {
        displayComponentsKeys: [
            "line1",
            "line2",
            "city",
            "territory",
            "countryCode",
        ],
        fieldsToValidate: isRequired ?[
            "line1",
            "city",
            "territory",
            "countryCode"
        ] : []
    }
};
