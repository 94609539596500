import "../../style/ConfirmationModal.css";

const ConfirmationModal = ({
  children,
  isOpen,
  onCancel,
  onConfirm,
  titleText,
  confirmText,
  cancelText
}) => {
  if (!isOpen) {
    return null;
  }

  return (
    <div className="confirmation-modal-overlay">
      <div className="confirmation-modal-content">
        <button className="confirmation-modal-content-close" onClick={onCancel}></button>
        <h2 className="confirmation-modal-content-title">{titleText}</h2>
        <div className="confirmation-modal-content-body">
          {children}
        </div>
        <div className="confirmation-modal-content-actions">
          <button
            className="confirmation-modal-content-actions-button confirmation-modal-content-actions-confirm"
            onClick={onConfirm}
          >
            {confirmText}
          </button>
          <button
            className="confirmation-modal-content-actions-button confirmation-modal-content-actions-cancel"
            onClick={onCancel}
          >
            {cancelText}
          </button>
        </div>
      </div>
    </div>
  );
};

export default ConfirmationModal;
