import { useContext } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";

import "../../style/MultiSelectModifier.css";

const MultiSelectOption = ({ lineItem, option }) => {
  const configsContext = useContext(Configs);

  function isChecked(value) {
    return lineItem.selectedAddons?.value
      ?.find(
        (selectedAddon) =>
          selectedAddon?.selectedAddon?.value?.attribute === (option.presentation || option.name),
      )
      ?.values?.value?.some((selected) => selected.name === value);
  }

  function handleValueChecked(value) {
    const valueName = value.presentation || value.name;

    // change the line item selected option to what was selected
    const selectedAddon = lineItem.selectedAddons?.value?.find(
      (selectedAddon) =>
        selectedAddon?.selectedAddon?.value?.attribute === (option.presentation || option.name),
    );

    if (!selectedAddon) {
      // couldn't find the option to update. should never happen
      return;
    }

    if (isChecked(valueName)) {
      // uncheck it by removing the value from the selectedAddon values signal on the line item
      selectedAddon.values.value = selectedAddon.values.value.filter(
        (selected) => selected.name !== valueName,
      );
    } else {
      // check it by adding the value to the selectedAddon values signal on the line item
      selectedAddon.values.value = [
        ...selectedAddon.values.value,
        {
          name: valueName,
          costAdjustment: {
            value: value?.costAdjustment?.value || 0,
            currencyCode: configsContext?.configs?.currency,
          },
        },
      ];
    }
  }

  const checkboxes = option.values.map((value) => {
    const valueName = value.presentation || value.name;

    return (
      <label className="modifier-value">
        <input
          key={valueName}
          onClick={handleValueChecked.bind(this, value)}
          type="checkbox"
          checked={isChecked(valueName)}
          value={valueName}
        />
        <span className="modifier-value-text">
          {valueName}
          {value.costAdjustment?.value
            ? ` (+${formatIntegerCurrencyString(
                value.costAdjustment.value,
                configsContext?.configs?.currency,
                configsContext?.configs?.ecommerceLocaleLanguage,
              )})`
            : ""}
        </span>
      </label>
    );
  });

  return (
    <div className="multi-select-container">
      <div className="modifier-title">{option.presentation || option.name}</div>
      <div className="modifier-checkboxes">{checkboxes}</div>
    </div>
  );
};

export default MultiSelectOption;
