export const getShippingAddresses = (isRequired: boolean, ) => {
    return {
        displayComponentsKeys: [
            "shippingLine1",
            "shippingLine2",
            "shippingCity",
            "shippingTerritory",
            "shippingZip",
        ],
        fieldsToValidate: isRequired ?[
            "shippingLine1",
            "shippingCity",
            "shippingTerritory",
            "shippingZip"
        ] : []
    }
};
