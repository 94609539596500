import { useContext, useEffect } from "preact/hooks";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { EMIT_LOAD_FIXED_AMOUNT } from "../../helpers/event-definitions";

import TipsForm from "../shared/TipsForm";
import NotesForm from "../shared/NotesForm";

const FixedPriceForm = ({ payLink, trackCustomEvent, order }) => {
  const configsContext = useContext(Configs);

  // track custom event on first load
  useEffect(() => {
    if (trackCustomEvent) {
      trackCustomEvent(EMIT_LOAD_FIXED_AMOUNT);
    }
  }, []);

  // fixed price
  return (
    <div className="container">
      <h1 className="item-layout-title">{payLink.title}</h1>
      <h1 className="item-layout-amount">
        {formatIntegerCurrencyString(
          payLink.amount,
          configsContext?.configs?.currency,
          configsContext?.configs?.ecommerceLocaleLanguage,
        )}
      </h1>
      <p className="item-layout-description">{payLink.description}</p>
      <NotesForm
        notesRequired={payLink?.metadata?.isNotesRequired}
        notesSignal={order?.notes}
        trackCustomEvent={trackCustomEvent}
      />
      <TipsForm payLink={payLink} order={order} />
    </div>
  );
};

export default FixedPriceForm;
