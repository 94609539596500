const recurrenceRateLookup = {
  DAY: "daily",
  WEEK: "weekly",
  MONTH: "monthly",
  YEAR: "yearly",
};

/**
 * Gets invoice recurrence rate
 * @param {Invoice} invoice
 * @returns
 */
export function getInvoiceRecurrenceRate(invoice) {
  if (!invoice?.recurringPlan) {
    return null;
  }

  const invoiceRecurrenceFrequencyInterval = invoice.recurringPlan.frequencyInterval;
  const invoiceRecurrenceFrequencyCount = invoice.recurringPlan.frequencyCount;

  return (
    invoiceRecurrenceFrequencyCount > 1 ?
    `every ${invoiceRecurrenceFrequencyCount} ${invoiceRecurrenceFrequencyInterval?.toLowerCase()}s` :
    recurrenceRateLookup[invoiceRecurrenceFrequencyInterval]
  );
}
