/**
 * Single page pay link checkout
 * Used for Fixed, Custom amount, Single-select list pay links
 */

import { useState, useEffect, useRef, useContext } from "preact/hooks";
import { route } from "preact-router";
import { Text } from "preact-i18n";

import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString, formatLocale } from "../../helpers/formatters";
import { getActionButtonStyle, isLightBackground } from "../../helpers/button";
import { EMIT_PAY_BUTTON_CLICK, EMIT_SUCCESS } from "../../helpers/event-definitions";
import {
  CARD_AGREEMENT_STYLES,
  COLLECT_OPTIONS,
  getCollectFieldsFromOrder,
  getEcommerceTransactionOptions,
  validateOrder,
} from "../../helpers/collect";
import { getOrderTotal } from "../../helpers/order";
import { composeArrayKeysToObject } from "../../helpers/common";
import { getBillingAddresses } from "../../helpers/addresses-fields/billing";
import { getShippingAddresses } from "../../helpers/addresses-fields/shipping";

import { charge } from "../../rest/charge";
import { calculateSurchargeFee } from "../../rest/fees";

import "../../style/PoyntCollectForm.css";

import PaymentAgreement from "./PaymentAgreement";

const CardCollect = ({
  payLink,
  order,
  product,
  setTransaction,
  setSurchargeData,
  setShowSurchargeConfirmation,
  onCollectError,
  showError,
  charging,
  setCharging,
  trackCustomEvent,
}) => {
  const [disabled, setDisabled] = useState(true);
  const [paymentMethod, setPaymentMethod] = useState(null);
  const [saveCardOnFile, setSaveCardOnFile] = useState(null);

  const configsContext = useContext(Configs);

  // use a ref for permanent component state
  // that shouldn't trigger rerender on change
  const collectRef = useRef();

  /**
   * Triggered when Collect is validated
   * @param {Event} event
   */
  function onValidated(event) {
    setDisabled(!event.data.validated);
  }

  /**
   * Triggered when payment method is selected
   * @param {Event} event
   */
  function onPaymentMethodSelected(event) {
    setPaymentMethod(event?.data?.paymentMethod || null);
  }

  /**
   * Triggered when card on file flag is changed
   * @param {Event} event
   */
  function onCardOnFileFlagChanged(event) {
    setSaveCardOnFile(event?.data?.value || null);
  }

  /**
   * Triggered when nonce is ready
   * @param {Event} event
   */
  async function onNonce(event) {
    try {
      // if nonce not returned, fail
      if (!event?.data?.nonce) {
        throw new Error();
      }

      showError(false);
      setCharging(true);

      const chargeOptions = {
        ...getEcommerceTransactionOptions(
          configsContext?.configs,
          payLink,
          order,
          product,
          event.data,
        ),
        emailAddress: event?.data?.emailAddress,
        phone: event?.data?.phone,
        nonce: event?.data?.nonce,
        ...(payLink?.metadata?.allowSaveCard &&
        event?.data?.cardOnFile &&
        event?.data?.cardAgreement
          ? {
              savedCard: {
                cardOnFile: event?.data?.cardOnFile,
                cardAgreement: event?.data?.cardAgreement,
                recurringPlanId: event?.data?.recurringPlanId,
              },
            }
          : {}),
        ...(payLink?.enableACHPayment === "1" &&
        payLink.metadata?.paymentMethods &&
        event?.data?.fundingSourceType
          ? {
              fundingSourceType: event?.data?.fundingSourceType,
            }
          : {}),
        isPayableDomain: window.location.href.includes('//pay.')
      };

      // calculate surcharge fee
      if (payLink.isSurchargeFeatureEnabled && event?.data?.fundingSourceType === "CREDIT_DEBIT") {
        const surchargeFee = await calculateSurchargeFee(configsContext?.configs?.env, chargeOptions);

        if (surchargeFee?.amount > 0) {
          setSurchargeData({ surchargeFee, chargeOptions, event });
          setShowSurchargeConfirmation(true);

          setCharging(false);

          return;
        }
      }

      const transaction = await charge(configsContext?.configs?.env, chargeOptions);

      setCharging(false);
      setTransaction(transaction);

      if (trackCustomEvent) {
        trackCustomEvent(EMIT_SUCCESS);
      }
      route("/transaction-complete");

      // Metrics.track(MetricEvents.ORDER_COMPLETED_POYNT_APPLE_PAY_IMPRESSION);
    } catch (error) {
      console.log("onNonce error", error, error && error.stack);
      showError("Transaction failed. Please try again.");
    }
  }

  // bootstrap collect if it's not there
  useEffect(() => {
    if (!collectRef.current) {
      const collect = new TokenizeJs(configsContext?.configs?.businessId, payLink.applicationId); // collect
      const isAddressRequired = payLink.metadata?.isAddressRequired;
      const collectBillingAddress = payLink.metadata?.collectBillingAddress;
      const collectShippingAddress = payLink.metadata?.collectShippingAddress;
      const billingAddressesObj = getBillingAddresses(isAddressRequired && collectBillingAddress);
      const shippingAddressesObj = getShippingAddresses(
        isAddressRequired && collectShippingAddress,
      );
      const params = new URLSearchParams(window?.location?.search);
      const showACH =
        payLink?.enableACHPayment === "1" &&
        payLink?.metadata?.paymentMethods &&
        params?.get("sourceApp") !== "wam.paybutton" &&
        window?.PayButtonsJS?.sourceApp !== "wam.paybutton";

      const collectOptions = {
        ...COLLECT_OPTIONS,
        iFrame: {
          ...COLLECT_OPTIONS.iFrame,
          height: payLink.metadata?.allowSaveCard ? "550px" : "485px",
        },
        displayComponents: {
          ...COLLECT_OPTIONS.displayComponents,
          ...(payLink.metadata?.allowPhone ? { phone: true } : {}),
          collectShippingAddress: collectShippingAddress,
          shippingAddressSameAsBilling: collectBillingAddress,
          showShippingAddressSameAsBillingCheckbox: collectBillingAddress,
          ...composeArrayKeysToObject(
            billingAddressesObj.displayComponentsKeys,
            collectBillingAddress,
          ),
          ...composeArrayKeysToObject(
            shippingAddressesObj.displayComponentsKeys,
            collectShippingAddress,
          ),
          ...((showACH || payLink.isSurchargeFeatureEnabled) ? { paymentNote: true } : {}),
        },
        additionalFieldsToValidate: [
          ...COLLECT_OPTIONS.additionalFieldsToValidate,
          ...(payLink.metadata?.allowPhone && payLink.metadata?.requirePhone ? ["phone"] : []),
          ...billingAddressesObj.fieldsToValidate,
          ...shippingAddressesObj.fieldsToValidate,
        ],
        fields: getCollectFieldsFromOrder(order),
        locale: formatLocale(configsContext?.configs?.ecommerceLocaleLanguage),
        ...(payLink.metadata?.allowSaveCard
          ? {
              enableCardOnFile: true,
              cardAgreementOptions: {
                businessName: configsContext?.configs?.ecommerceBusinessName,
                businessWebsite: configsContext?.configs?.businessWebsite,
                businessPhone: configsContext?.configs?.businessPhone,
                style: {
                  ...CARD_AGREEMENT_STYLES,
                  acceptButton: {
                    ...CARD_AGREEMENT_STYLES.acceptButton,
                    "background-color": configsContext?.configs?.ecommerceCheckoutButtonColor,
                    color: isLightBackground(configsContext?.configs?.ecommerceCheckoutButtonColor)
                      ? "black"
                      : "white",
                  },
                },
              },
            }
          : {}),
        ...(showACH
          ? {
              paymentMethods: payLink.metadata.paymentMethods,
              style: {
                theme: "paylink",
              },
              customCss: {}
            }
          : {}),
        enableSurchargeFee: payLink.isSurchargeFeatureEnabled,
      };

      collect.mount("poynt-collect", document, collectOptions);

      collect.on("validated", onValidated);
      collect.on("nonce", onNonce);
      collect.on("error", onCollectError);

      collect.on("iframe_height_change", (event) => {
        if (event?.data?.height) {
          const iFrame = document.getElementById("poynt-collect-v2-iframe");
          iFrame?.style?.setProperty("height", event.data.height + 1 + "px");
        }
      });

      collect.on("payment_method_selected", onPaymentMethodSelected);
      collect.on("card_on_file_flag_changed", onCardOnFileFlagChanged);

      if (payLink?.metadata?.allowSaveCard) {
        collect.on("card_on_file_error", (event) => {
          showError(event?.data?.error);
        });
      }

      collectRef.current = collect;
    }
  }, []);

  function handleCharge() {
    const error = validateOrder(configsContext?.configs, payLink, order);
    if (error) {
      showError(error);
      throw new Error(error);
    }

    showError(false);
    setCharging(true);

    collectRef.current.getNonce({
      businessId: configsContext?.configs?.businessId,
    });

    if (trackCustomEvent) {
      trackCustomEvent(EMIT_PAY_BUTTON_CLICK);
    }
  }

  // custom background color on the pay button
  let payButtonStyle;
  if (!disabled) {
    payButtonStyle = getActionButtonStyle(configsContext?.configs?.ecommerceCheckoutButtonColor);
  }

  const amountString = formatIntegerCurrencyString(
    getOrderTotal(order),
    payLink.currency,
    configsContext?.configs?.ecommerceLocaleLanguage,
  );

  return (
    <Fragment>
      <div id="response"></div>
      {charging ? (
        <div className="pay-button-container">
          <button className="pay-button" style={payButtonStyle} disabled={true}>
            <Text id="PAYMENT_BUTTON_CHARGING">Charging...</Text>
          </button>
        </div>
      ) : (
        <div className="pay-button-container">
          <button
            onClick={handleCharge}
            className="pay-button"
            style={payButtonStyle}
            disabled={disabled}
          >
            {payLink.isSurchargeFeatureEnabled ? (
              <Text id="PAYMENT_BUTTON_CONTINUE">Continue</Text>
            ) : (
              <Text id="PAYMENT_BUTTON_AMOUNT" fields={{ amount: amountString }}>
                Pay {amountString}
              </Text>
            )}
          </button>
        </div>
      )}
      <PaymentAgreement
        payLink={payLink}
        order={order}
        paymentMethod={paymentMethod}
        saveCardOnFile={saveCardOnFile}
      />
    </Fragment>
  );
};

export default CardCollect;
