export enum FulfillmentStatus {
  UNFULFILLED = "UNFULFILLED",
  ON_HOLD = "ON_HOLD",
  CONFIRMED = "CONFIRMED",
  AWAITING = "AWAITING",
  IN_PROGRESS = "IN_PROGRESS",
  PARTIALLY_FULFILLED = "PARTIALLY_FULFILLED",
  FULFILLED = "FULFILLED",
  PARTIALLY_RETURNED = "PARTIALLY_RETURNED",
  RETURNED = "RETURNED",
  CANCELED = "CANCELED",
}
