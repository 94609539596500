import { signal, computed } from "@preact/signals";

/**
 * Creates a signal from a unified selectedAddon object
 * @param {selectedAddon} data
 * @returns
 */
export function createSelectedAddonSignal(data) {
  const sku = signal(data?.sku);
  const values = signal(data?.values || []);

  // helpers for order form
  const type = signal(data?.type);

  const selectedAddon = computed(() => {
    const addonObject = {
      attribute: data?.attribute,
      values: values.value,
    };

    // attach sku if set
    if (sku.value) {
      addonObject.sku = sku.value;
    }

    return addonObject;
  });

  return {
    sku,
    type,
    values,
    selectedAddon,
  };
}
