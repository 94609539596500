import { Fragment } from "preact";
import { useContext, useEffect, useState } from "preact/hooks";
import { Text } from "preact-i18n";
import CurrencyInput from "react-currency-input";

import { EMIT_LOAD_LEGACY_OPTIONS, EMIT_OPTION } from "../../helpers/event-definitions";
import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { handleCustomPriceChange } from "../../helpers/customPrice";
import { getFormattingOptions } from "../../helpers/formatters/formatting-options";
import { getActionButtonStyle } from "../../helpers/button";

import NotesForm from "../shared/NotesForm";
import TipsForm from "../shared/TipsForm";

const LegacyOptionsListForm = ({ payLink, trackCustomEvent, order }) => {
  const [value, setValue] = useState(0);
  const [currentAmount, setCurrentAmount] = useState(payLink.metadata.amountOptions[0]);

  const configsContext = useContext(Configs);

  const formattingOptions = getFormattingOptions(configsContext?.configs?.ecommerceLocaleLanguage);

  // track custom event on first load
  useEffect(() => {
    if (trackCustomEvent) {
      trackCustomEvent(EMIT_LOAD_LEGACY_OPTIONS);
    }
  }, []);

  // handler that gets triggered on option selection
  function handleSelect(amount, e) {
    // update the order item signal
    const item = order.items.value[0];
    if (item.unitPrice.value !== amount) {
      item.unitPrice.value = amount || 0;
    }
    order.tip.value = 0;

    setCurrentAmount(amount);
    setValue(0); // empty custom amount field

    if (trackCustomEvent) {
      trackCustomEvent(EMIT_OPTION, { amount });
    }
  }

  return (
    <div className="container item-description-custom-amount">
      <h1 className="item-layout-title">{payLink.title}</h1>
      <p className="item-layout-description">{payLink.description}</p>

      <div className="item-selected-list-section">
        <label>
          <Text id="SELECT_AMOUNT_LABEL">Select Amount</Text>
        </label>
        <div className="item-selected-list-rows">
          {payLink.metadata.amountOptions.map((amount) => {
            let style;

            if (amount === currentAmount) {
              style = getActionButtonStyle(
                configsContext?.configs?.ecommerceCheckoutButtonColor,
                "background: #2F373B; color: white;",
              );
            } else {
              style = "";
            }

            const amountString = formatIntegerCurrencyString(
              amount,
              payLink.currency,
              configsContext?.configs?.ecommerceLocaleLanguage,
            );

            return (
              <div className="amount-box" onClick={handleSelect.bind(this, amount)} style={style}>
                <span className="amount-box-text">{amountString}</span>
              </div>
            );
          })}
        </div>
      </div>

      {payLink.metadata?.allowCustomPrice ? (
        <Fragment>
          <p className="item-selected-list-rows-or">
            <Text id="OR_AMOUNT_LABEL">or</Text>
          </p>
          <form>
            <label>
              <Text id="CUSTOM_AMOUNT_LABEL">Enter Amount</Text>
            </label>
            <CurrencyInput
              className="custom-amount-input"
              value={value}
              thousandSeparator={formattingOptions.thousandsSeparator}
              decimalSeparator={formattingOptions.decimalSeparator}
              prefix={formattingOptions.symbolPrefix}
              suffix={formattingOptions.symbolSuffix}
              allowNegativeValue={false}
              onChangeEvent={(_event, newValue) => {
                order.tip.value = 0;
                const intValue = handleCustomPriceChange(
                  order,
                  configsContext?.configs?.ecommerceLocaleLanguage,
                  newValue,
                );
                setCurrentAmount(intValue);
                setValue(newValue);
              }}
            />
          </form>
        </Fragment>
      ) : null}
      <NotesForm
        notesRequired={payLink?.metadata?.isNotesRequired}
        notesSignal={order?.notes}
        trackCustomEvent={trackCustomEvent}
      />
      <TipsForm payLink={payLink} order={order} />
    </div>
  );
};

export default LegacyOptionsListForm;
