import { signal, computed } from "@preact/signals";

/**
 * Creates a signal from a unified selectedOption object
 * @param {SelectedOption} data
 * @returns
 */
export function createSelectedOptionSignal(data) {
  const values = signal(data?.values || []);

  // helpers for order form
  const type = signal(data?.type);

  const selectedOption = computed(() => {
    return {
      attribute: data?.attribute,
      values: values.value,
    };
  });

  return {
    type,
    values,
    selectedOption,
  };
}
