import { useContext, useEffect, useState } from "preact/hooks";
import CurrencyInput from "react-currency-input";

import { EMIT_LOAD_OPTIONS, EMIT_OPTION } from "../../helpers/event-definitions";
import { Configs } from "../../helpers/context";
import { formatIntegerCurrencyString } from "../../helpers/formatters";
import { getOrderItemSignal, handleCustomPriceChange } from "../../helpers/customPrice";
import { getFormattingOptions } from "../../helpers/formatters/formatting-options";

import NotesForm from "../shared/NotesForm";
import TipsForm from "../shared/TipsForm";

const OptionsListForm = ({ payLink, trackCustomEvent, order }) => {
  const [selectedId, setSelectedId] = useState(0);

  const configsContext = useContext(Configs);

  const formattingOptions = getFormattingOptions(configsContext?.configs?.ecommerceLocaleLanguage);

  // track custom event on first load
  useEffect(() => {
    if (trackCustomEvent) {
      trackCustomEvent(EMIT_LOAD_OPTIONS);
    }
  }, []);

  // handler that gets triggered on option selection
  function handleSelect(id) {
    // update the order item signal
    const item = order.items.value[0];
    item.unitPrice.value = payLink.metadata?.options?.[id]?.amount || 0;
    item.name.value = payLink.metadata?.options?.[id]?.title || item.name.value;
    order.tip.value = 0;

    setSelectedId(id);

    if (trackCustomEvent) {
      trackCustomEvent(EMIT_OPTION);
    }
  }

  return (
    <div className="container item-description-custom-amount">
      <h1 className="item-layout-title">{payLink.title}</h1>
      <p className="item-layout-description">{payLink.description}</p>
      <div className="item-selected-list-section">
        <div className="item-select-row">
          {payLink.metadata?.options?.map((option, i) => {
            // radio button for selecting the current option
            const selectInput = (
              <input
                type="radio"
                checked={i === selectedId ? true : false}
                onClick={handleSelect.bind(this, i)}
              />
            );

            if (option.allowCustomPrice) {
              // custom price form

              const customAmountInput = (
                <CurrencyInput
                  className="custom-amount-input"
                  value={i === selectedId ? getOrderItemSignal(order) / 100 : 0}
                  thousandSeparator={formattingOptions.thousandsSeparator}
                  decimalSeparator={formattingOptions.decimalSeparator}
                  prefix={formattingOptions.symbolPrefix}
                  suffix={formattingOptions.symbolSuffix}
                  allowNegativeValue={false}
                  onChangeEvent={(_event, value) => {
                    order.tip.value = 0;
                    handleCustomPriceChange(
                      order,
                      configsContext?.configs?.ecommerceLocaleLanguage,
                      value,
                    );
                  }}
                />
              );

              return (
                <form>
                  {option.title ? (
                    <Fragment>
                      <label className="option-amount-label">
                        {selectInput}
                        {option.title}
                      </label>
                      <div class="custom-amount">{customAmountInput}</div>
                    </Fragment>
                  ) : (
                    <label className="option-amount-label-custom">
                      {selectInput}
                      <div class="custom-amount-no-title">
                        <div className="custom-amount-currency">$</div>
                        {customAmountInput}
                      </div>
                    </label>
                  )}
                </form>
              );
            }

            // fixed amount
            const amountString = formatIntegerCurrencyString(
              option.amount,
              payLink.currency,
              configsContext?.configs?.ecommerceLocaleLanguage,
            );

            return (
              <div>
                <label className="option-amount-label">
                  {selectInput}
                  <span className="radio-box-text">
                    {option.title ? `${option.title}: ` : null}
                    <span className="amount">{amountString}</span>
                  </span>
                </label>
              </div>
            );
          })}
        </div>
      </div>
      <NotesForm
        notesRequired={payLink?.metadata?.isNotesRequired}
        notesSignal={order?.notes}
        trackCustomEvent={trackCustomEvent}
      />
      <TipsForm payLink={payLink} order={order} />
    </div>
  );
};

export default OptionsListForm;
