import { ConfigsProvider } from "../helpers/context";
import Application from "./Application";

const App = () => {
  return (
    <ConfigsProvider>
      <Application />
    </ConfigsProvider>
  );
};

export default App;
