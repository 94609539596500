import FixedPriceForm from "./FixedPriceForm";
import CustomPriceForm from "./CustomPriceForm";
import OptionsListForm from "./OptionsListForm";
import LegacyOptionsListForm from "./LegacyOptionsListForm";

import "../../style/SinglePageForm.css";

const SinglePageForm = ({ payLink, trackCustomEvent, order }) => {
  if (payLink?.urlType === "L") {
    if (payLink?.metadata?.amountOptions) {
      // select price from a list
      return (
        <LegacyOptionsListForm
          payLink={payLink}
          order={order}
          trackCustomEvent={trackCustomEvent}
        />
      );
    }

    if (payLink?.metadata?.options) {
      // select price from a list
      return (
        <OptionsListForm payLink={payLink} order={order} trackCustomEvent={trackCustomEvent} />
      );
    }
  }

  if (payLink?.isCustomPrice) {
    // variable/custom amount screen if isCustomPrice === true and it's a payment
    return <CustomPriceForm payLink={payLink} order={order} trackCustomEvent={trackCustomEvent} />;
  }

  if (payLink) {
    // fixed price screen if custom price doesnt exist and if it's a payment
    return <FixedPriceForm payLink={payLink} order={order} trackCustomEvent={trackCustomEvent} />;
  }
};

export default SinglePageForm;
